import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserAvatar, CurrentAccountContext, AuthContext } from 'rmg-react-utils';
import { Row, Col, Dropdown, Menu } from 'antd';
import { defaultErrorHandler } from 'utils/bugsnag';

const PageHeader = () => {
  const { user, logout } = useContext(AuthContext);
  const { account } = useContext(CurrentAccountContext);

  const userMenu = (
    <Menu className="user-menu">
      <Menu.Item>
        <Link to="/profile">
          Contact information
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/account">
          Account
        </Link>
      </Menu.Item>
      <Menu.Item onClick={() => logout().catch(defaultErrorHandler)}>Logout</Menu.Item>
    </Menu>
  );

  return (
    <header className="hero-header">
      <div className="container">
        <Row>
          <Col span="12">
            <Link to="/claims" className="hero-logo-container">
              <div className="hero-logo">
                <img src={account.logo && account.logo.url} alt="FFA" />
              </div>
            </Link>
          </Col>
          <Col span="12" className="text-right">
            <ul className="hero-menu right-menu">
              <li>
                <Dropdown overlay={userMenu} placement="bottomRight" trigger={['click']}>
                  <span className="menu-item">
                    <UserAvatar size="large" user={user} />
                    <span className="d-sm-none ml-3">{ user.name }</span>
                  </span>
                </Dropdown>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </header>
  );
};

export default PageHeader;
