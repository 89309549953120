import React from 'react';
import { ClaimContextProvider } from 'rmg-react-utils';
import Claim from '.';

const ClaimContainer = () => (
  <ClaimContextProvider>
    <Claim />
  </ClaimContextProvider>
);

export default ClaimContainer;
