import React from 'react';
import moment from 'moment';
import DashboardRouter from 'router/dashboard';
import PageHeader from 'layouts/shared/PageHeader';
import { isIE } from 'react-device-detect';
import { t } from 'rmg-react-utils';
import IEBanner from 'layouts/shared/IEBanner';
import WelcomeNotice from './WelcomeNotice';

const Dashboard = () => (
  <div className={isIE ? 'is-ie' : ''}>
    <IEBanner />
    <PageHeader />

    <div className="container dashboard-container">
      <DashboardRouter />
    </div>

    <footer className="main-footer">
      <div className="container text-right">
        { t('globals.footer_text', { year: moment().year() }) }
      </div>
    </footer>

    <WelcomeNotice />
  </div>
);

export default Dashboard;
