import React, { useContext } from 'react';
import { Form, Row, Col } from 'antd';
import {
  buildDynamicForm,
  ConditionalField,
  ClaimContext,
  CurrentAccountContext,
  DynamicFieldWithErrors
} from 'rmg-react-utils';
import FormGroup from 'components/FormGroup';
import { defaultErrorHandler } from 'utils/bugsnag';

const ClaimDetailsForm = () => {
  const { claim, updateClaim } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const [form] = Form.useForm();

  if (!claimStructure.claimDetails) {
    return null;
  }

  const claimDetailsFields = () => (
    claimStructure.claimDetails.map((detailsSection) => detailsSection.fields).flat()
  );

  return (
    <Form layout="vertical" form={form} initialValues={buildDynamicForm(claim, claimDetailsFields())}>
      {
        claimStructure.claimDetails.map((section) => (
          <FormGroup title={section.label} key={section.key} count="1">
            <Row gutter={30}>
              {
                section.fields && section.fields.map((field) => (
                  <ConditionalField model={claim} field={field} key={field.key}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} key={field.key}>
                      <DynamicFieldWithErrors
                        model={claim}
                        field={field}
                        updateModel={(values) => updateClaim(values).catch(defaultErrorHandler)}
                        form={form}
                      />
                    </Col>
                  </ConditionalField>
                ))
              }
            </Row>
          </FormGroup>
        ))
      }
    </Form>
  );
};

export default ClaimDetailsForm;
