import React from 'react';
import ClaimFormRouter from 'router/ClaimForm';
import { Row, Col } from 'antd';
import StepHelper from './StepHelper';
import WizardHeader from './WizardHeader';

const CreateClaim = () => (
  <>
    <Row>
      <WizardHeader />

      <Col xs={{ span: 24 }} sm={{ span: 20, offset: 2 }} md={{ span: 22, offset: 1 }}>
        <div className="step-helper-container">
          <StepHelper />
        </div>
      </Col>
    </Row>

    <ClaimFormRouter />
  </>
);

export default CreateClaim;
