import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { t } from 'rmg-react-utils';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const AddItemBtn = ({ disabled, claimId, sectionKey }) => {
  if (disabled) {
    return (
      <Button type="secondary" ghost icon={<PlusOutlined />} disabled>
        {t('item.add')}
      </Button>
    );
  }

  return (
    <Link
      to={`/claims/${claimId}/sections/${sectionKey}/items/new`}
      className="ant-btn ant-btn-secondary ant-btn-background-ghost"
    >
      <PlusOutlined />
      <span>{t('item.add')}</span>
    </Link>
  );
};

AddItemBtn.defaultProps = {
  disabled: false
};

AddItemBtn.propTypes = {
  disabled: PropTypes.bool,
  claimId: PropTypes.number.isRequired,
  sectionKey: PropTypes.string.isRequired
};

export default AddItemBtn;
