import React from 'react';
import UploadsForm from '../../components/UploadsForm';
import WizardFooter from '../WizardFooter';
import CreateClaimFormContainer from '../CreateClaimFormContainer';

const ClaimUploads = () => (
  <>
    <CreateClaimFormContainer>
      <UploadsForm />
    </CreateClaimFormContainer>

    <WizardFooter previousStep="sections" />
  </>
);

export default ClaimUploads;
