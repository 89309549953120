import React from 'react';
import FormGroup from 'components/FormGroup';
import BankDetailsForm from 'layouts/claim/components/BankDetailsForm';
import PersonalDetailsForm from 'layouts/claim/components/PersonalDetailsForm';
import PersonalDetailsExtraForm from 'layouts/claim/components/PersonalDetailsExtraForm';

const ClaimClaimant = () => (
  <>
    <FormGroup title="Personal details">
      <PersonalDetailsExtraForm />
    </FormGroup>

    <PersonalDetailsForm />

    <BankDetailsForm />
  </>
);

export default ClaimClaimant;
