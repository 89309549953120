import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CurrentAccountContext, t } from 'rmg-react-utils';
import Title from 'components/Title';
import SectionDetails from './SectionDetails';

const ItemsTable = ({ showEmptySections }) => {
  const { claimStructure } = useContext(CurrentAccountContext);

  return (
    <>
      <Title text={t('activerecord.models.item.other')} />

      {
        claimStructure.sections && claimStructure.sections.map((sectionStructure) => (
          <SectionDetails
            sectionStructure={sectionStructure}
            showIfEmpty={showEmptySections}
            key={sectionStructure.key}
          />
        ))
      }
    </>
  );
};

ItemsTable.defaultProps = {
  showEmptySections: false
};

ItemsTable.propTypes = {
  showEmptySections: PropTypes.bool
};

export default ItemsTable;
