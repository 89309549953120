import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { ClaimsContext, Loader } from 'rmg-react-utils';
import Title from 'components/Title';
import MainMenu from 'layouts/dashboard/MainMenu';
import DocumentSection from './Section';

const DocumentsList = () => {
  const { claims, loading } = useContext(ClaimsContext);

  return (
    <Row>
      <MainMenu selected="documents" />

      <Col xs={{ span: 24 }} sm={{ span: 19, offset: 1 }} md={{ span: 18, offset: 1 }}>
        <Title text="Download templates" />

        {
          loading ? (<Loader />) : claims.map((claim) => (<DocumentSection claim={claim} />))
        }
      </Col>
    </Row>
  );
};

export default DocumentsList;
