import React from 'react';
import PropTypes from 'prop-types';

const SectionDivider = ({ title }) => (
  <div className="section-title">
    <h3 className="section-title-text">{title}</h3>
  </div>
);

SectionDivider.propTypes = {
  title: PropTypes.string.isRequired
};

export default SectionDivider;
