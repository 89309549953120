import React from 'react';
import { Link } from 'react-router-dom';
import { Result } from 'antd';
import AuthContainer from './container';

const ThankYou = () => (
  <AuthContainer>
    <Result
      status="success"
      title="Thank you for your registration!"
      subTitle="You will receive a confirmation email shortly."
      extra={[
        <Link to="/login" key="login-link">Log in</Link>
      ]}
    />
  </AuthContainer>
);

export default ThankYou;
