import React, { useContext, useEffect } from 'react';
import { ClaimContext, Loader } from 'rmg-react-utils';
import Title from 'components/Title';
import { defaultErrorHandler } from 'utils/bugsnag';
import CommentsList from './List';
import NewComment from './NewComment';

const Comments = () => {
  const { sendAction, comments, getComments, commentsLoaded } = useContext(ClaimContext);
  const title = `Inbox (${comments.length})`;

  useEffect(() => {
    getComments().catch(defaultErrorHandler);
    sendAction('clear_claimant_comments').catch(defaultErrorHandler);
  }, []);

  if (!commentsLoaded) {
    return (
      <Loader />
    );
  }

  return (
    <section className="comments">
      <Title text={title} />

      <CommentsList comments={comments} />

      <NewComment />
    </section>
  );
};

export default Comments;
