import React, { useContext, useState } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { CurrentAccountContext, AuthContext } from 'rmg-react-utils';
import AuthContainer from './container';
import { defaultErrorHandler } from '../../utils/bugsnag';

const Login = () => {
  const { login } = useContext(AuthContext);
  const { account } = useContext(CurrentAccountContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);

    login(values).catch((error) => {
      setLoading(false);

      defaultErrorHandler(error);
    });
  };

  const accountName = account.slug === 'football' ? 'the Claims Portal for the Australia Football Member Federations Insurance Program' : account.slug;

  return (
    <AuthContainer title={`Log in ${accountName}`}>
      {
        account.slug === 'football' && (
          <Alert
            message="Welcome to Claims Gateway."
            description={(
              <>
                <p>
                  <Link to="/signup">Sign up Here</Link>
                  &nbsp;if this is your first claim.
                </p>

                <p>
                  If you have used this portal before, please use your login details which you have
                  previously created (Do not use your Football Australia / Play Football login
                  details in this portal)
                </p>

                <p>
                  If you need assistance in accessing the ClaimsGateway portal, please contact&nbsp;
                  <a href="mailto:football@gowgates.com.au">football@gowgates.com.au</a>
                </p>
              </>
            )}
            type="info"
            className="mb-4"
            showIcon
          />
        )
      }

      <Form form={form} onFinish={onFinish} className="login-form">
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
            type="email"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Row>
          <Col xs={24} sm={12}>
            <Checkbox>Remember me</Checkbox>
          </Col>
          <Col xs={24} sm={12} className="text-right mb-4">
            <p>
              <Link to="/forgot-password">Forgot password</Link>
            </p>
            <p>
              <Link to="/resend-confirmation">Resend confirmation instructions</Link>
            </p>
          </Col>
        </Row>

        <Button type="primary" htmlType="submit" className="w-100 mb-4" loading={loading}>
          Log in
        </Button>
      </Form>

      <p>
        <span>Don&apos;t have an account? </span>
        <Link to="/signup">Sign up here</Link>
      </p>
    </AuthContainer>
  );
};

export default Login;
