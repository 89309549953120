import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

const NotFound = () => {
  const { goBack } = useHistory();

  return (
    <section className="error-page text-center">
      <h1 className="mt-4 mb-2">404</h1>
      <p className="mb-4">The page you were looking for doesn&apos;t exist.</p>

      <Button type="primary" onClick={goBack}>Go back</Button>
    </section>
  );
};

export default NotFound;
