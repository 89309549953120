import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CurrentAccountContext, ClaimContext } from 'rmg-react-utils';
import ItemForm from './components/Form';

const NewItem = () => {
  const { claim } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const [item, setItem] = useState(null);
  const [structure, setStructure] = useState({});
  const { sectionId, claimId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!claim[sectionId]) {
      history.push({ pathname: `/claims/${claimId}/sections/${sectionId}/new` });
    } else {
      setItem({ sectionId: claim[sectionId].id });
    }
  }, [sectionId, claim]);

  useEffect(() => {
    setStructure(claimStructure.sections.find((s) => s.key === sectionId));
  }, [claimStructure, sectionId]);

  if (item && structure) {
    return (
      <ItemForm currentItem={item} structure={structure.items} sectionKey={structure.key} />
    );
  }

  return null;
};

export default NewItem;
