import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Steps, Row, Col } from 'antd';
import { CurrentAccountContext, ClaimContext } from 'rmg-react-utils';
import { CreateStepsContext } from 'contexts/CreateStepsContext';

const WizardHeader = () => {
  const { claim } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const { getTabByIndex, isTabDisabled, currentTabIndex } = useContext(CreateStepsContext);
  const history = useHistory();

  const changeStep = (clickedStep) => {
    const tab = getTabByIndex(clickedStep);

    history.push(`/claims/${claim.id}/${tab.path}`);
  };

  // doesnt display header on thank you page
  if (!claimStructure.createTabs[currentTabIndex]) {
    return null;
  }

  return (
    <Row className="wizard-header box">
      <Col xs={0} sm={24}>
        <Steps current={currentTabIndex} className="mt-2 mb-2" size="small" onChange={changeStep}>
          {
            claimStructure.createTabs.map((tab, index) => (
              <Steps.Step
                title={tab.title}
                disabled={isTabDisabled(index)}
                key={tab.key}
              />
            ))
          }
        </Steps>
      </Col>

      <Col xs={24} sm={0}>
        Step&nbsp;
        {currentTabIndex + 1}
        &nbsp;of&nbsp;
        {claimStructure.createTabs.length}
        &nbsp;&gt;&nbsp;
        <strong>{ claimStructure.createTabs[currentTabIndex].title }</strong>
      </Col>
    </Row>
  );
};

export default WizardHeader;
