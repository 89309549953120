import React, { useContext } from 'react';
import { CurrentAccountContext } from 'rmg-react-utils';
import ClaimDetailsForm from 'layouts/claim/components/ClaimDetailsForm';
import WizardFooter from '../WizardFooter';
import CreateClaimFormContainer from '../CreateClaimFormContainer';

const ClaimDetails = () => {
  const { claimStructure } = useContext(CurrentAccountContext);

  if (!claimStructure.claimDetails) {
    return null;
  }

  return (
    <>
      <CreateClaimFormContainer>
        <ClaimDetailsForm />
      </CreateClaimFormContainer>

      <WizardFooter previousStep="personal-details" />
    </>
  );
};

export default ClaimDetails;
