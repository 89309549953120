import React, { useContext, useState } from 'react';
import { Form, Button, Alert } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { PasswordWithErrors, AuthContext } from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';
import AuthContainer from './container';

const ChangePassword = () => {
  const { changePassword, user } = useContext(AuthContext);
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    changePassword(values, queryString.parse(search).reset_password_token)
      .catch(defaultErrorHandler).finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthContainer title="Set a password">

      {
        user.errors && user.errors.resetPasswordToken && (
          <Alert message="Error" description="Reset password token is invalid" type="error" showIcon className="mb-4" />
        )
      }

      <Form form={form} onFinish={onFinish} className="login-form">
        <PasswordWithErrors name="password" placeholder="Password" errors={user.errors} />
        <PasswordWithErrors
          name="passwordConfirmation"
          placeholder="Password confirmation"
          errors={user.errors}
        />

        <Button type="primary" htmlType="submit" className="w-100 mb-4" loading={loading}>
          Set my password
        </Button>

        <p>
          <Link to="/login">
            Log in
          </Link>
        </p>
      </Form>
    </AuthContainer>
  );
};

export default ChangePassword;
