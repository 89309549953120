import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu, Typography, Dropdown, Tooltip } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { UserAvatar, formatDateTime, ClaimContext, AuthContext } from 'rmg-react-utils';
import { Link } from 'react-router-dom';
import { defaultErrorHandler } from 'utils/bugsnag';
import File from './File';

const Comment = ({ comment, showSubtitle }) => {
  const { deleteComment } = useContext(ClaimContext);
  const { user } = useContext(AuthContext);

  const dropdownMenu = () => (
    <Menu>
      <Menu.Item>
        <div
          role="menuitem"
          onClick={() => deleteComment(comment).catch(defaultErrorHandler)}
          onKeyUp={() => deleteComment(comment).catch(defaultErrorHandler)}
          tabIndex={-1}
        >
          Delete comment
        </div>
      </Menu.Item>
    </Menu>
  );

  const commentClasses = (
    `comment ${comment.user.id === user.id ? 'outgoing' : 'ingoing'}`
  );

  return (
    <article className={commentClasses}>
      <header className="mb-2">
        <div>
          <span className="comment-avatar">
            <UserAvatar user={comment.user} />
          </span>
          {comment.user.name}
          <span> — </span>

          <Typography.Text type="secondary">
            {formatDateTime(comment.createdAt)}
          </Typography.Text>

          {
            comment.permissions.canDestroy && (
              <Dropdown
                trigger={['click']}
                placement="bottomRight"
                overlay={dropdownMenu}
                className="ml-2"
              >
                <MoreOutlined />
              </Dropdown>
            )
          }
        </div>

        {
          showSubtitle && comment.commentableType === 'Item' && (
            <div className="comment-subtitle">
              Comment regarding expense&nbsp;
              <Link to={`sections/${comment.sectionType}/items/${comment.commentableId}`}>
                #
                {comment.commentableId}
              </Link>
            </div>
          )
        }

      </header>

      <div className="comment-inner">
        {/* eslint-disable-next-line react/no-danger */}
        <div className="comment-text" dangerouslySetInnerHTML={{ __html: comment.text }} />

        {
          comment.documents.length > 0 && (
            <div className="mt-3">
              <h4>Attachments:</h4>
              {
                comment.documents.map((document) => (
                  <File
                    key={document.id}
                    url={document.file.url}
                    type={document.file.type}
                    name={document.file.name}
                  />
                ))
              }
            </div>
          )
        }
      </div>
    </article>
  );
};

Comment.defaultProps = {
  showSubtitle: false
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  showSubtitle: PropTypes.bool
};

export default Comment;
