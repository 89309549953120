import React, { useContext } from 'react';
import { ClaimContext } from 'rmg-react-utils';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

const MoreInfoThankYou = () => {
  const { claim } = useContext(ClaimContext);

  return (
    <section>
      <Result
        status="success"
        title="Thank you"
        subTitle="The new information has been submitted and will be reviewed shortly."
        extra={[
          <Link key="show" to={`/claims/${claim.id}`} className="ant-btn ant-btn-primary">
            Show claim details
          </Link>,
          <Link key="return" to="/claims" className="ant-btn">
            Return to claims list
          </Link>
        ]}
      />
    </section>
  );
};

export default MoreInfoThankYou;
