import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Typography } from 'antd';
import { t } from 'rmg-react-utils';

const DocumentsSectionTitle = ({ claim, kind }) => {
  const errorMessages = _.get(claim, `errors.${kind}`);
  const title = t(`activerecord.attributes.claim.${kind}`);

  if (errorMessages) {
    return (
      <>
        <h4>
          <Typography.Text type="danger">{title}</Typography.Text>
        </h4>
        <p>
          <small>
            <Typography.Text type="danger">{ errorMessages.join(', ') }</Typography.Text>
          </small>
        </p>
      </>
    );
  }

  return (
    <h4>{title}</h4>
  );
};

DocumentsSectionTitle.propTypes = {
  claim: PropTypes.object.isRequired,
  kind: PropTypes.string.isRequired
};

export default DocumentsSectionTitle;
