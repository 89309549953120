/** @jsxRuntime classic */
// support for IE11. https://github.com/facebook/create-react-app/issues/9906
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { isBugsnagActive } from 'utils/bugsnag';
import App from './App';
import { getTranslations } from './utils/I18n';
import * as serviceWorker from './serviceWorker';

getTranslations();

if (isBugsnagActive) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()]
  });
  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  ReactDOM.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <App />,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
