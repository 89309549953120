import React, { useState, useContext } from 'react';
import { AuthContext } from 'rmg-react-utils';
import { Modal } from 'antd';
import { defaultErrorHandler } from 'utils/bugsnag';

const WelcomeNotice = () => {
  const [visible, setVisible] = useState(true);
  const { user, updateUser } = useContext(AuthContext);

  const handleOk = () => {
    updateUser({ showWelcomeMessage: false }).catch(defaultErrorHandler);
    setVisible(false);
  };

  if (!user.showWelcomeMessage) {
    return null;
  }

  return (
    <Modal
      title="Welcome to Claims Gateway"
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={handleOk}
      cancelText="Close"
      okText="Ok, don't show this again"
    >
      <p>
        Since you last visited we have updated and improved our claims portal.
        All the information that you had previously submitted is still available.
      </p>

      <p>
        Should you have any questions please feel free to contact our team directly using the
        inbox feature available on submitted claims or by email at&nbsp;
        <a href="mailto: sportsclaims@gowgates.com.au">sportsclaims@gowgates.com.au</a>
        .
      </p>
    </Modal>
  );
};

export default WelcomeNotice;
