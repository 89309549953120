import React, { useContext, useEffect } from 'react';
import { Form, Row, Col } from 'antd';
import {
  SelectWithErrors,
  AppContext,
  ClaimContext,
  AuthContext,
  InputWithErrors,
  t
} from 'rmg-react-utils';
import FormGroup from 'components/FormGroup';
import { defaultErrorHandler } from 'utils/bugsnag';

const PersonalDetailsForm = () => {
  const { updateUser, user } = useContext(AuthContext);
  const { claim } = useContext(ClaimContext);
  const { states, getStates, countries, getCountries } = useContext(AppContext);

  useEffect(() => {
    getStates();
  }, [getStates]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  return (
    <Form layout="vertical" initialValues={claim.user}>
      <FormGroup title={t('user.addressDetails')} count="2" divider>
        <Row gutter={30}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <SelectWithErrors
              label={t('activerecord.attributes.user.country')}
              name="country"
              errors={claim.user.errors}
              updateModel={(values) => updateUser(values).catch(defaultErrorHandler)}
              showSearch
              options={countries}
            />
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <InputWithErrors
              label={t('activerecord.attributes.user.address')}
              name="address"
              errors={claim.user.errors}
              updateModel={(values) => updateUser(values).catch(defaultErrorHandler)}
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <InputWithErrors
              label={t('activerecord.attributes.user.suburb')}
              name="suburb"
              errors={claim.user.errors}
              updateModel={(values) => updateUser(values).catch(defaultErrorHandler)}
            />
          </Col>

          {
            user.country === 'AU' && (
              <>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <SelectWithErrors
                    label={t('activerecord.attributes.user.state')}
                    name="state"
                    errors={claim.user.errors}
                    updateModel={(values) => updateUser(values).catch(defaultErrorHandler)}
                    showSearch
                    options={states}
                  />
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <InputWithErrors
                    label={t('activerecord.attributes.user.postcode')}
                    name="postcode"
                    errors={claim.user.errors}
                    updateModel={(values) => updateUser(values).catch(defaultErrorHandler)}
                  />
                </Col>
              </>
            )
          }
        </Row>
      </FormGroup>

      <FormGroup title={t('user.contactInformation')} count="3" divider>
        <Row gutter={30}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <InputWithErrors
              label={t('activerecord.attributes.user.phone')}
              name="phone"
              errors={claim.user.errors}
              updateModel={(values) => updateUser(values).catch(defaultErrorHandler)}
              version="phone"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <InputWithErrors
              label={t('activerecord.attributes.user.workPhone')}
              name="workPhone"
              errors={claim.user.errors}
              updateModel={(values) => updateUser(values).catch(defaultErrorHandler)}
              version="phone"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <InputWithErrors
              label={t('activerecord.attributes.user.mobilePhone')}
              name="mobilePhone"
              errors={claim.user.errors}
              updateModel={(values) => updateUser(values).catch(defaultErrorHandler)}
              version="phone"
            />
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default PersonalDetailsForm;
