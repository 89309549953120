import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import {
  DynamicFormatter,
  MoneyFormatter,
  ApiContext,
  CurrentAccountContext,
  Loader,
  t
} from 'rmg-react-utils';
import StatusFormatter from 'components/StatusFormatter';
import RequiresActionIcon from 'layouts/claim/components/RequiresActionIcon';
import CommentsIcon from 'layouts/claim/components/CommentsIcon';
import { defaultErrorHandler } from 'utils/bugsnag';
import AddItemDropdownContainer from './components/AddItemDropdownContainer';

const { Column } = Table;

const RowDetails = ({ claim }) => {
  const { claimStructure } = useContext(CurrentAccountContext);
  const { client } = useContext(ApiContext);
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState('initial');
  const history = useHistory();

  useEffect(() => {
    if (status === 'initial') {
      setStatus('loading');

      client.get(`/claims/${claim.id}`).then((response) => {
        const claimItems = claimStructure.sections.map((section) => {
          if (response.data[section.key]) {
            return response.data[section.key].items.map((item) => (
              {
                ...item,
                sectionType: section.label,
                sectionKey: section.key,
                localDescription: section.backofficeItemsTable[0],
                itemCalculationType: section.itemCalculationType
              }
            ));
          }

          return null;
        }).flat().filter(Boolean);

        setItems(claimItems);
        setStatus('loaded');
      }).catch(defaultErrorHandler);
    }
  }, [client, claim.id, claimStructure.sections, status]);

  if (status !== 'loaded') {
    return (
      <div className="sub-table-loader">
        <Loader />
      </div>
    );
  }

  const onRow = (record) => ({
    onClick: () => {
      history.push(`/claims/${claim.id}/sections/${record.sectionKey}/items/${record.id}`);
    }
  });

  return (
    <>
      <Table
        locale={{ emptyText: 'No expenses for this claim' }}
        dataSource={items}
        rowKey={(record) => record.id}
        pagination={false}
        className="sub-table"
        onRow={onRow}
        rowClassName={(record) => (record.requiresAction ? 'action-required' : '')}
      >
        <Column
          title="Claim Type"
          dataIndex="sectionType"
        />
        <Column
          title="Description"
          key="localDescription"
          render={(record) => (
            <DynamicFormatter model={record} field={record.localDescription} />
          )}
        />
        <Column
          title="Claimed Amount"
          key="claimedAmount"
          render={(record) => (
            <MoneyFormatter
              value={record.claimedAmount}
              currency={record.claimedAmountCurrency}
            />
          )}
        />
        <Column
          title="Status"
          key="actions"
          render={(_text, record) => (
            <StatusFormatter status={record.status} paidAmount={record.paidAmount} />
          )}
        />

        <Column
          title=""
          key="actions"
          className="text-right"
          render={(record) => (
            <span className="text-large">
              <RequiresActionIcon showIcon={record.requiresAction} label={t('item.requiresAction')} />

              <CommentsIcon commentsCount={record.commentsCount} />
            </span>
          )}
        />
      </Table>

      <p className="mt-3 text-right">
        <AddItemDropdownContainer
          claim={claim}
          sections={claimStructure.sections}
          canCreateItems={claim.permissions.canCreateItems}
        />
      </p>
    </>
  );
};

RowDetails.propTypes = {
  claim: PropTypes.object.isRequired
};

export default RowDetails;
