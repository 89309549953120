import React, { useContext } from 'react';
import { Button, Alert, Row, Col, Typography } from 'antd';
import { ClaimContext } from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';
import ItemsTable from '../../components/ItemsTable';

const ClaimItems = () => {
  const { claim, sendAction } = useContext(ClaimContext);

  const submitNewItems = () => {
    sendAction('submit_new_items').catch(defaultErrorHandler);
  };

  const { Text } = Typography;
  const hasNewItems = claim.permissions.submitNewItems;

  const alertContent = (
    <Row justify="space-between" align="middle">
      <Col className="text-left" xs={{ span: 24 }} sm={{ span: 18 }}>
        <Text type="warning">You have unsubmitted expenses!</Text>
      </Col>
      <Col className="text-right" xs={{ span: 24 }} sm={{ span: 6 }}>
        <Button type="primary" onClick={submitNewItems}>
          Submit new expenses
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      {hasNewItems && <Alert type="warning" message={alertContent} className="mb-4" />}

      <ItemsTable showEmptySections />
    </>
  );
};

export default ClaimItems;
