import React, { useContext, useEffect } from 'react';
import { Form, Input, Radio, Row, Col, Button, message, Typography } from 'antd';
import {
  stringToDate,
  UserAvatar,
  FormItemWithErrors,
  DatePickerWithErrors,
  SelectWithErrors,
  AppContext,
  AuthContext,
  InputWithErrors,
  t,
  isUnprocessableEntity
} from 'rmg-react-utils';
import BottomBar from 'components/BottomBar';
import SectionDivider from 'components/SectionDivider';
import { defaultErrorHandler } from 'utils/bugsnag';

const Profile = () => {
  const { user, updateUser } = useContext(AuthContext);
  const { states, getStates, countries, getCountries } = useContext(AppContext);
  const [form] = Form.useForm();

  useEffect(() => {
    getStates();
  }, [getStates]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  const onFinish = (values) => {
    updateUser(values).then(() => {
      message.success('User updated');
    }).catch((error) => {
      if (isUnprocessableEntity(error)) {
        message.error(t('globals.errorsInForm'));
      }

      defaultErrorHandler(error);
    });
  };

  const uploadAvatar = (e) => {
    const file = e.target.files[0];
    updateUser({ avatar: file }).catch(defaultErrorHandler);
  };

  const convertedUser = { ...user, birthDate: stringToDate(user.birthDate) };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} initialValues={convertedUser}>
      <div className="create-claim-form">
        <Row gutter={30}>
          <Col xs={{ span: 24 }} md={{ span: 5, offset: 1 }} className="text-center">
            <UserAvatar className="ant-avatar-giant" user={user} />
            <p className="mb-0 mt-2">
              <label className="avatar-file-label" htmlFor="user_avatar">
                <Typography.Text type="primary">Upload new avatar</Typography.Text>
              </label>
              <input
                id="user_avatar"
                name="user_avatar"
                className="avatar-file-input"
                type="file"
                onChange={uploadAvatar}
                accept="image/*"
              />
            </p>
            <h3 className="mt-4 mb-0">{user.name}</h3>
            <p className="m-0">
              <Typography.Text type="secondary">{user.email}</Typography.Text>
            </p>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 17 }}>
            <SectionDivider title="General" />

            <Row gutter={30}>
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <InputWithErrors name="firstName" label="First Name" errors={user.errors} />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <InputWithErrors name="middleName" label="Middle Name" errors={user.errors} />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <InputWithErrors name="lastName" label="Last Name" errors={user.errors} />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <DatePickerWithErrors name="birthDate" label="Date of Birth" errors={user.errors} />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <SelectWithErrors
                  label="Country of birth"
                  name="birthCountry"
                  errors={user.errors}
                  showSearch
                  options={countries}
                />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <FormItemWithErrors name="gender" label="Gender" errors={user.errors}>
                  <Radio.Group>
                    <Radio value>Male</Radio>
                    <Radio value={false}>Female</Radio>
                  </Radio.Group>
                </FormItemWithErrors>
              </Col>
            </Row>

            <SectionDivider title={t('user.addressDetails')} />

            <Row gutter={30}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <InputWithErrors name="address" label="Street address" errors={user.errors} />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <InputWithErrors name="suburb" label="Suburb" errors={user.errors} />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <SelectWithErrors name="state" label="State" errors={user.errors} options={states} />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <InputWithErrors name="postcode" label="Post code" errors={user.errors} />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <SelectWithErrors
                  name="country"
                  label="Country"
                  errors={user.errors}
                  showSearch
                  options={countries}
                />
              </Col>
            </Row>

            <SectionDivider title={t('user.contactInformation')} />

            <Row gutter={30}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <InputWithErrors name="phone" label="Home phone" errors={user.errors} version="phone" />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <InputWithErrors
                  name="workPhone"
                  label="Work phone"
                  errors={user.errors}
                  version="phone"
                />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <InputWithErrors
                  name="mobilePhone"
                  label="Mobile phone"
                  errors={user.errors}
                  version="phone"
                />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <FormItemWithErrors name="email" label="Email" errors={user.errors}>
                  <Input />
                </FormItemWithErrors>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>

      <BottomBar
        rightContent={(
          <Button type="primary" htmlType="submit">Save</Button>
        )}
      />
    </Form>
  );
};

export default Profile;
