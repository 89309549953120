import React, { useContext, useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { ApiContext, AuthContext } from 'rmg-react-utils';
import Login from 'layouts/auth/Login';
import Signup from 'layouts/auth/Signup';
import ThankYou from 'layouts/auth/ThankYou';
import Confirmation from 'layouts/auth/Confirmation';
import Unlock from 'layouts/auth/Unlock';
import ForgotPassword from 'layouts/auth/ForgotPassword';
import ChangePassword from 'layouts/auth/ChangePassword';
import ResendConfirmation from 'layouts/auth/ResendConfirmation';
import Dashboard from 'layouts/dashboard';
import NotFound from 'layouts/shared/NotFound';
import InternalServerError from 'layouts/shared/InternalServerError';
import BrowserList from 'layouts/shared/BrowserList';
import PrivateRoute from './PrivateRoute';
import { setBugsnagUser, removeBugsnagUser } from '../utils/bugsnag';

const AppRouter = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    isAuthorized,
    hasError,
    setIsAuthorized,
    setHasError,
    isLoggedIn
  } = useContext(ApiContext);
  const { user } = useContext(AuthContext);

  // Every time the users changes, it updates bugsnag
  useEffect(() => {
    // TODO: when any of the user's attributes change, there will be a post to bugsnag.
    //       but in reality we only want to post when the user logs in.
    if (isLoggedIn && user) {
      setBugsnagUser(user);
    } else {
      removeBugsnagUser();
    }
  }, [isLoggedIn, user, setBugsnagUser, removeBugsnagUser]);

  // Actions to to at every location change
  useEffect(() => {
    // Sends Google Analytics pageviews
    if (window.gtag) {
      window.gtag('config', 'UA-172130978-1', { page_path: history.location.pathname });
    }

    if (history.action === 'PUSH') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, history.action]);

  // When showing error page and going back (POP action) it clears the errors to try render again
  useEffect(() => {
    if (history.action === 'POP') {
      setIsAuthorized(true);
      setHasError(false);
    }
  }, [history.location, history.action, setIsAuthorized, setHasError]);

  if (!isAuthorized) {
    return (
      <NotFound />
    );
  }

  if (hasError) {
    return (
      <InternalServerError />
    );
  }

  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route exact path="/resend-confirmation">
        <ResendConfirmation />
      </Route>
      <Route exact path="/users/confirmation">
        <Confirmation />
      </Route>
      <Route exact path="/users/unlock">
        <Unlock />
      </Route>
      <Route exact path="/users/password/edit">
        <ChangePassword />
      </Route>
      <Route exact path="/thankyou">
        <ThankYou />
      </Route>
      <Route exact path="/browserlist">
        <BrowserList />
      </Route>
      <PrivateRoute path="/">
        <Dashboard />
      </PrivateRoute>
    </Switch>
  );
};

export default AppRouter;
