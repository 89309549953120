import React, { useContext, useState, useEffect } from 'react';
import { Form, Button, message } from 'antd';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ApiContext, AuthContext, Loader, InputWithErrors, t } from 'rmg-react-utils';
import AuthContainer from './container';
import { defaultErrorHandler } from '../../utils/bugsnag';
import SpanLink from '../../components/SpanLink';

const Unlock = () => {
  const { unlock, resendUnlock } = useContext(AuthContext);
  const { isLoggedIn, errorMessage } = useContext(ApiContext);
  const { search } = useLocation();
  const [initState, setInitState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const [isShowingForm, setShowingForm] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      setInitState('loaded');
      return;
    }

    if (initState) {
      return;
    }

    setInitState('loading');

    unlock(queryString.parse(search).unlock_token).then(() => {
      history.push('/login');
      message.success(t('devise.unlocks.unlocked'));
    }).catch((error) => {
      setInitState('loaded');

      defaultErrorHandler(error);
    });
  }, [initState, unlock, search]);

  const onFinish = (values) => {
    setLoading(true);
    resendUnlock(values).then(() => {
      history.push('/login');
    })
      .catch((error) => {
        setLoading(false);

        defaultErrorHandler(error);
      });
  };

  if (initState !== 'loaded') {
    return <Loader />;
  }

  if (errorMessage) {
    return (
      <AuthContainer title="Confirm account">
        {
          isShowingForm ? (
            <Form form={form} onFinish={onFinish} className="login-form">
              <InputWithErrors
                name="email"
                placeholder={t('activerecord.attributes.user.email')}
              />
              <Button type="primary" htmlType="submit" className="w-100 mb-4" loading={loading}>
                {t('user.setMyPassword')}
              </Button>
            </Form>
          ) : (
            <p>
              <SpanLink onClick={() => setShowingForm(true)}>
                Resend unlock instructions
              </SpanLink>
            </p>
          )
        }

        <p>
          <Link to="/login">
            Log in
          </Link>
        </p>
      </AuthContainer>
    );
  }

  if (isLoggedIn) {
    return (
      <Redirect to="/" />
    );
  }

  return (
    <AuthContainer title="Confirm account">
      <p>
        <Link to="/login">
          Log in
        </Link>
      </p>
    </AuthContainer>
  );
};

export default Unlock;
