import React from 'react';
import PropTypes from 'prop-types';
import BottomBar from 'components/BottomBar';
import BackLink from './BackLink';
import NextStep from './NextStep';

const WizardFooter = ({ previousStep, label, action }) => (
  <BottomBar
    leftContent={(
      <BackLink step={previousStep} />
    )}
    rightContent={(
      <NextStep label={label} action={action} />
    )}
  />
);

WizardFooter.defaultProps = {
  previousStep: null,
  label: undefined,
  action: undefined
};

WizardFooter.propTypes = {
  previousStep: PropTypes.string,
  label: PropTypes.string,
  action: PropTypes.func
};

export default WizardFooter;
