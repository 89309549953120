import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { ConditionalField, CurrentAccountContext } from 'rmg-react-utils';
import Document from './Document';

const ClaimDocumentList = ({ claim }) => {
  const { claimStructure } = useContext(CurrentAccountContext);

  return (
    <Row gutter={30}>
      {
        claimStructure.documents.map((field) => (
          <ConditionalField model={claim} field={field} key={field.key}>
            <Col xs={{ span: 12 }} md={{ span: 8 }}>
              <Document claimId={claim.id} kind={field.key} />
            </Col>
          </ConditionalField>
        ))
      }
    </Row>
  );
};

ClaimDocumentList.propTypes = {
  claim: PropTypes.object.isRequired
};

export default ClaimDocumentList;
