import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Col, Dropdown, Menu, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import pluralize from 'pluralize';
import { t, CurrentAccountContext } from 'rmg-react-utils';
import LeftMenu from 'layouts/shared/LeftMenu';

const MainMenu = ({ selected }) => {
  const { hasUploads } = useContext(CurrentAccountContext);

  const mainMenu = (
    <Menu selectedKeys={[selected]} className="claim-menu">
      <Menu.Item key="claims">
        <Link to="/claims">
          {t('activerecord.models.claim.other')}
        </Link>
      </Menu.Item>
      {
        hasUploads && (
          <Menu.Item key="documents">
            <Link to="/documents">
              {t('activerecord.models.document.other')}
            </Link>
          </Menu.Item>
        )
      }
    </Menu>
  );

  return (
    <>
      <Col xs={0} sm={5}>
        <LeftMenu>
          {mainMenu}
        </LeftMenu>
      </Col>

      <Col xs={24} sm={0} className="box box-small wizard-header">
        <Row align="middle" justify="space-between">
          <Col>
            {t(`activerecord.models.${pluralize.singular(selected)}.other`)}
          </Col>
          <Col>
            <Dropdown className="mobile-claim-menu" overlay={mainMenu} trigger={['click']}>
              <Button type="link" icon={<MenuOutlined />} />
            </Dropdown>
          </Col>
        </Row>
      </Col>
    </>
  );
};

MainMenu.propTypes = {
  selected: PropTypes.string.isRequired
};

export default MainMenu;
