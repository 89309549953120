import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, message } from 'antd';
import {
  ConditionalField,
  buildDynamicForm,
  ClaimContext,
  DynamicFieldWithErrors,
  isUnprocessableEntity,
  t
} from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';

const SectionForm = ({ section, structure }) => {
  const { saveSection, destroySection } = useContext(ClaimContext);
  const [loading, isLoading] = useState(false);
  const [form] = Form.useForm();

  const setSectionField = (params) => {
    saveSection({ ...params, id: section.id, type: structure.key }).catch(defaultErrorHandler);
  };

  const handleDeleteSection = () => {
    isLoading(true)
    destroySection(section).catch((error) => {
      if (isUnprocessableEntity(error)) {
        message.error(error.response.data.errors.base.join(', '));
      }

      defaultErrorHandler(error);
    }).finally(() => {
      isLoading(false);
    });
  };

  return (
    <>
      <Form form={form} layout="vertical" initialValues={buildDynamicForm(section, structure.fields)}>
        <Row gutter={30}>
          {
            structure.fields && structure.fields.map((field) => (
              <ConditionalField model={section} field={field} key={field.key}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <DynamicFieldWithErrors
                    model={section}
                    field={field}
                    updateModel={setSectionField}
                    form={form}
                  />
                </Col>
              </ConditionalField>
            ))
          }
        </Row>
      </Form>

      {
        section.permissions && section.permissions.canDestroy && (
          <Button danger type="default" onClick={handleDeleteSection} loading={loading}>
            {t('section.remove')}
          </Button>
        )
      }
    </>
  );
};

SectionForm.propTypes = {
  section: PropTypes.object.isRequired,
  structure: PropTypes.object.isRequired
};

export default SectionForm;
