import React from 'react';
import PropTypes from 'prop-types';
import { t, MoneyFormatter } from 'rmg-react-utils';
import { paramCase } from 'change-case';

const StatusFormatter = ({ status, paidAmount }) => (
  <span className={`status-tag status-${paramCase(status)}`}>
    { t(`item.frontoffice_statuses.${status}`) }

    {
      status === 'paid' && (
        <>
          &nbsp;
          <MoneyFormatter value={paidAmount} />
        </>
      )
    }
  </span>
);

StatusFormatter.propTypes = {
  status: PropTypes.string.isRequired,
  paidAmount: PropTypes.string.isRequired
};

export default StatusFormatter;
