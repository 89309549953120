import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Box from 'components/Box';
import { CurrentAccountContext } from 'rmg-react-utils';

const BrowserList = () => {
  const { account } = useContext(CurrentAccountContext);

  return (
    <div className="container">
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 18, offset: 3 }}
        >
          <div className="auth-container">
            <Box title="Update your browser">
              <div className="text-center mb-4">
                <Link to="/">
                  <img src={account.logo && account.logo.url} alt={account.logo} className="logo" />
                </Link>
              </div>

              <div className="auth-form">
                <h1>Supported browser list</h1>

                <p>Here is the list of supported browsers by this page.</p>
                <p>
                  We strongly recommend that you use the latest version of one of the following list
                </p>

                <Row className="text-center mt-5">
                  <Col span={6}>
                    <div className="browser-box">
                      <a href="https://www.google.com/chrome" rel="noreferrer" target="_blank">
                        <img src="outdated/chrome.jpg" alt="Google Chrome" />
                        <p>Google Chrome</p>
                      </a>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="browser-box">
                      <a href="https://www.mozilla.org/en-US/firefox/new" rel="noreferrer" target="_blank">
                        <img src="outdated/firefox.jpg" alt="Firefox" />
                        <p>Firefox</p>
                      </a>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="browser-box">
                      <a href="https://www.microsoft.com/en-us/edge" rel="noreferrer" target="_blank">
                        <img src="outdated/edge.jpg" alt="Microsoft Edge" />
                        <p>Microsoft Edge</p>
                      </a>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="browser-box">
                      <a href="https://www.opera.com/download" rel="noreferrer" target="_blank">
                        <img src="outdated/opera.jpg" alt="Opera" />
                        <p>Opera</p>
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>

              <Link to="/">Back to homepage</Link>
            </Box>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BrowserList;
