import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'antd';
import {
  buildDynamicForm,
  ConditionalField,
  t,
  ClaimContext,
  CurrentAccountContext,
  DynamicFieldWithErrors
} from 'rmg-react-utils';
import FormGroup from 'components/FormGroup';
import { defaultErrorHandler } from 'utils/bugsnag';

const BasicDetailsForm = ({ headerNumber }) => {
  const { claim, updateClaim } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const [claimInfoForm] = Form.useForm();

  if (!claimStructure.basicDetails) {
    return null;
  }

  return (
    <FormGroup title={t(`${claimStructure.type}.sections.claim_information`)} count={headerNumber}>
      <Form
        layout="vertical"
        form={claimInfoForm}
        initialValues={buildDynamicForm(claim, claimStructure.basicDetails.fields)}
      >
        <Row gutter={30}>
          {
            claimStructure.basicDetails.fields.map((field) => (
              <Col xs={{ span: 24 }} md={{ span: 12 }} key={field.key}>
                <ConditionalField model={claim} field={field} key={field.key}>
                  <DynamicFieldWithErrors
                    model={claim}
                    field={field}
                    updateModel={(values) => updateClaim(values).catch(defaultErrorHandler)}
                    form={claimInfoForm}
                  />
                </ConditionalField>
              </Col>
            ))
          }
        </Row>
      </Form>
    </FormGroup>
  );
};

BasicDetailsForm.defaultProps = {
  headerNumber: null
};

BasicDetailsForm.propTypes = {
  headerNumber: PropTypes.string
};

export default BasicDetailsForm;
