import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { t } from 'rmg-react-utils';
import AddItemDropdown from './AddItemDropdown';

const AddItemDropdownContainer = ({ claim }) => {
  if (claim.permissions.canCreateItems) {
    return <AddItemDropdown claim={claim} />;
  }

  return (
    <Tooltip title={t('item.cannotCreateInDeclinedClaim')}>
      <span>
        <AddItemDropdown claim={claim} disabled />
      </span>
    </Tooltip>
  );
};

AddItemDropdownContainer.propTypes = {
  claim: PropTypes.object.isRequired
};

export default AddItemDropdownContainer;
