import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, Modal } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
  ConditionalField,
  buildDynamicForm,
  serializeDynamicForm,
  FilesUploaderWithErrors,
  ClaimContext,
  DynamicFieldWithErrors,
  t,
  isUnprocessableEntity
} from 'rmg-react-utils';
import ArrowBack from 'components/ArrowBack';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CreateClaimFormContainer from 'layouts/claim/create/CreateClaimFormContainer';
import BottomBar from 'components/BottomBar';
import Title from 'components/Title';
import { defaultErrorHandler } from 'utils/bugsnag';

const ItemForm = ({ currentItem, structure, sectionKey }) => {
  const { claim, getClaim, saveItem } = useContext(ClaimContext);
  const [form] = Form.useForm();
  const [item, setItem] = useState({ ...currentItem });
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const setItemField = (params) => {
    setItem({ ...item, ...params });
  };

  const save = (values) => {
    setLoading(true);

    const itemParams = {
      ...serializeDynamicForm(values, structure),
      id: item.id,
      sectionId: item.sectionId
    };

    saveItem(itemParams, sectionKey).then(() => {
      getClaim(claim.id).then(() => {
        history.push(`/claims/${claim.id}/items`);
      }).catch(defaultErrorHandler);
    }).catch((error) => {
      setLoading(false);

      if (isUnprocessableEntity(error)) {
        setItem({ ...item, errors: error.response.data.errors });
      }

      defaultErrorHandler(error);
    });
  };

  const confirmNoDocuments = (values) => {
    Modal.confirm({
      title: 'Do you want to submit this expense?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      content: (
        <>
          <p>
            You are submitting an expense without any supporting documentation.
            The claim is unlikely to be able to be paid without this.
          </p>
          <p>Do you wish to proceed?</p>
        </>
      ),
      onOk() {
        save(values);
      }
    });
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      if (values.documentsAttributes.length === 0) {
        confirmNoDocuments(values);
      } else {
        save(values);
      }
    });
  };

  return (
    <>
      <CreateClaimFormContainer>
        <Title text={t('item.add')} />
        <Form form={form} layout="vertical" initialValues={buildDynamicForm(item, structure)}>
          <Row gutter={30}>
            {
              structure.map((field) => (
                <ConditionalField model={item} field={field} key={field.key} excludeReadonly>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <DynamicFieldWithErrors
                      model={item}
                      field={field}
                      updateModel={setItemField}
                      form={form}
                    />
                  </Col>
                </ConditionalField>
              ))
            }

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <FilesUploaderWithErrors
                label="Supporting documents"
                name="documents"
                errors={item.errors}
                form={form}
                model={item}
              />
            </Col>
          </Row>
        </Form>
      </CreateClaimFormContainer>

      <BottomBar
        leftContent={(
          <Link to={`/claims/${claim.id}/items`} className="ant-btn ant-btn-link px-0">
            <ArrowBack text="Back" />
          </Link>
        )}
        rightContent={(
          <Button type="primary" onClick={handleSubmit} loading={isLoading}>Save Expense</Button>
        )}
      />
    </>
  );
};

ItemForm.propTypes = {
  currentItem: PropTypes.object.isRequired,
  structure: PropTypes.array.isRequired,
  sectionKey: PropTypes.string.isRequired
};

export default ItemForm;
