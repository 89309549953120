import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation, Link } from 'react-router-dom';
import { Row, Col, Dropdown, Button, Menu, Badge, Alert } from 'antd';
import ClaimDetailsRouter from 'router/ClaimDetails';
import { CurrentAccountContext, ClaimContext } from 'rmg-react-utils';
import LeftMenu from 'layouts/shared/LeftMenu';
import { MenuOutlined } from '@ant-design/icons';
import SubmitMoreInfo from './SubmitMoreInfo';

export const menuLabels = {
  items: 'Expenses',
  'claim-details': 'Claim Details',
  'personal-details': 'Personal Details',
  documents: 'Documents',
  'policy-info': 'Policy Information',
  inbox: 'Inbox',
  timeline: 'Timeline'
};

const Claim = () => {
  const { claim } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const { pathname } = useLocation();
  const [claimDetailsErrors, setClaimDetailsErrors] = useState(false);
  const [personalDetailsErrors, setPersonalDetailsErrors] = useState(false);
  const [documentErrors, setDocumentErrors] = useState(false);

  useEffect(() => {
    setClaimDetailsErrors(false);
    setPersonalDetailsErrors(false);
    setDocumentErrors(false);

    let found = null;
    let errorsInClaimDetails = false;
    let errorsInPersonalDetails = false;
    let errorsInDocuments = false;

    _.forIn(claim.errors, (value, key) => {
      // value can be null
      if (!value) {
        return;
      }

      // claim details
      if (claimStructure.claimDetails[0]) { // trip claims don't have the claimDetails structure
        found = _.find(claimStructure.claimDetails[0].fields, { key });
        if (found) {
          errorsInClaimDetails = true;
        }
      }

      found = _.find(claimStructure.basicDetails.fields, { key });
      if (found) {
        errorsInClaimDetails = true;
      }

      // personal details - claim part
      found = _.find(claimStructure.personalDetailsExtra.fields, { key });
      if (found) {
        errorsInPersonalDetails = true;
      }

      // bank details
      found = _.find(claimStructure.bankAccountDetails, { key });
      if (found) {
        errorsInPersonalDetails = true;
      }

      // Documents
      found = _.find(claimStructure.documents, { key });
      if (found) {
        errorsInDocuments = true;
      }
    });

    // personal details - user part (only necessary if there are no errors)
    if (!errorsInPersonalDetails) {
      errorsInPersonalDetails = !_.isEmpty(claim.user.errors);
    }

    // if there is already an error in claim details, no need to go through sections
    if (!errorsInClaimDetails) {
      claimStructure.sections.forEach((sectionStructure) => {
        // the claim might not have that section
        if (!claim[sectionStructure.key]) {
          return;
        }

        _.forIn(claim[sectionStructure.key].errors, (value, key) => {
          // value can be null
          if (!value) {
            return;
          }

          found = _.find(sectionStructure.fields, { key });

          if (found) {
            errorsInClaimDetails = true;
          }
        });
      });
    }

    setClaimDetailsErrors(errorsInClaimDetails);
    setPersonalDetailsErrors(errorsInPersonalDetails);
    setDocumentErrors(errorsInDocuments);
  }, [claim]);

  const selectedTab = () => pathname.split('/').slice(-1);

  const claimUrl = (key) => `/claims/${claim.id}/${key}`;

  const claimMenu = (
    <Menu selectedKeys={selectedTab()} className="claim-menu">
      <Menu.Item key="items">
        <Link to={claimUrl('items')}>
          {menuLabels.items}
        </Link>
      </Menu.Item>
      <Menu.Item key="claim-details">
        <Link to={claimUrl('claim-details')}>
          {menuLabels['claim-details']}
          <Badge dot={claimDetailsErrors} className="ml-2" />
        </Link>
      </Menu.Item>
      <Menu.Item key="personal-details">
        <Link to={claimUrl('personal-details')}>
          {menuLabels['personal-details']}
          <Badge dot={personalDetailsErrors} className="ml-2" />
        </Link>
      </Menu.Item>
      {
        claim.permissions.showDocuments && (
          <Menu.Item key="documents">
            <Link to={claimUrl('documents')}>
              {menuLabels.documents}
              <Badge dot={documentErrors} className="ml-2" />
            </Link>
          </Menu.Item>
        )
      }
      <Menu.Item key="policy-info">
        <Link to={claimUrl('policy-info')}>
          {menuLabels['policy-info']}
        </Link>
      </Menu.Item>
      <Menu.Item key="inbox">
        <Link to={claimUrl('inbox')}>
          {menuLabels.inbox}
          {
            claim.claimantUnreadCommentsCount > 0 && (
              <Badge count={claim.claimantUnreadCommentsCount} className="ml-2" />
            )
          }
        </Link>
      </Menu.Item>
      <Menu.Item key="timeline">
        <Link to={claimUrl('timeline')}>
          {menuLabels.timeline}
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Row>
      <Col xs={0} sm={5}>
        <LeftMenu title={`Claim #${claim.id}`}>
          {claimMenu}
        </LeftMenu>
      </Col>

      <Col xs={24} sm={0} className="box box-small wizard-header">
        <Row align="middle" justify="space-between">
          <Col>
            Claim #
            {claim.id}
            : &nbsp;
            <strong>{menuLabels[selectedTab()]}</strong>
          </Col>
          <Col>
            <Dropdown className="mobile-claim-menu" overlay={claimMenu} trigger={['click']}>
              <Button type="link" icon={<MenuOutlined />} />
            </Dropdown>
          </Col>
        </Row>
      </Col>

      <Col xs={{ span: 24 }} sm={{ span: 18, offset: 1 }}>
        <div className="claim-content">
          <SubmitMoreInfo />

          {
            claim.locked && (
              <Alert
                type="warning"
                className="mb-4"
                message={(
                  <>
                    { /* eslint-disable-next-line react/no-danger */ }
                    <p dangerouslySetInnerHTML={{ __html: claim.lockMessage }} />
                  </>
                )}
              />
            )
          }

          <ClaimDetailsRouter />
        </div>
      </Col>
    </Row>
  );
};

export default Claim;
