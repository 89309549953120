import React, { useContext, useEffect } from 'react';
import { Timeline, Typography, Row, Col } from 'antd';
import { t, formatDate, ClaimContext, Loader } from 'rmg-react-utils';
import Title from 'components/Title';
import { defaultErrorHandler } from 'utils/bugsnag';

const History = () => {
  const { history, getHistory, historyLoaded } = useContext(ClaimContext);

  useEffect(() => {
    getHistory().catch(defaultErrorHandler);
  }, []);

  if (!historyLoaded) {
    return (
      <Loader />
    );
  }

  return (
    <section>
      <Title text="Timeline" />

      <Row className="claim-timeline">
        <Col xs={{ span: 24 }} sm={{ span: 18 }} lg={{ span: 10 }}>
          <Timeline mode="left">
            {
              history.map((entry) => (
                <Timeline.Item
                  key={entry.id}
                  label={(
                    <>
                      <p className="m-0">
                        {formatDate(entry.createdAt, 'shortDate')}
                      </p>
                      <p className="m-0">
                        <small>
                          <Typography.Text type="secondary">
                            {formatDate(entry.createdAt, 'time')}
                          </Typography.Text>
                        </small>
                      </p>
                    </>
                  )}
                >
                  <p className="m-0">
                    { t(`claim.statuses.${entry.nextStatus}`) }
                  </p>
                  <p className="m-0">
                    <small>
                      <em>
                        <Typography.Text type="secondary">
                          { t('user.by', { name: entry.user ? entry.user.name : t('user.unknown') }) }
                        </Typography.Text>
                      </em>
                    </small>
                  </p>
                </Timeline.Item>
              ))
            }
          </Timeline>
        </Col>
      </Row>
    </section>
  );
};

export default History;
