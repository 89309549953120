import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from 'antd';
import { WarningFilled } from '@ant-design/icons';

const RequiresActionIcon = ({ showIcon, label }) => {
  if (!showIcon) {
    return null;
  }

  return (
    <Tooltip title={label}>
      <Typography.Text type="danger" className="text-large">
        <WarningFilled />
      </Typography.Text>
    </Tooltip>
  );
};

RequiresActionIcon.propTypes = {
  showIcon: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
};

export default RequiresActionIcon;
