import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { t } from 'rmg-react-utils';

const DeleteClaimIcon = ({ record, destroyClaim }) => {
  if (!record.permissions.canDestroy) {
    return null;
  }

  return (
    <Popconfirm
      title={t('claim.confirmDestroy')}
      okType="danger"
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
      onConfirm={(event) => {
        event.stopPropagation();
        destroyClaim(record.id);
      }}
      onCancel={(event) => event.stopPropagation()}
      onClick={(event) => event.stopPropagation()}
    >
      <span role="button" className="p-1 ml-2">
        <DeleteOutlined />
      </span>
    </Popconfirm>
  );
};

DeleteClaimIcon.propTypes = {
  record: PropTypes.object.isRequired,
  destroyClaim: PropTypes.func.isRequired
};

export default DeleteClaimIcon;
