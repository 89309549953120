import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { ClaimContext } from 'rmg-react-utils';
import { CreateStepsContext } from 'contexts/CreateStepsContext';
import { defaultErrorHandler } from 'utils/bugsnag';

const NextStep = ({ label, action, onError }) => {
  const { claim, sendAction } = useContext(ClaimContext);
  const { currentTab, nextTab } = useContext(CreateStepsContext);
  const history = useHistory();

  const save = () => {
    if (action) {
      action();
    } else {
      sendAction(currentTab.action).then(() => {
        message.success('Claim updated');
        history.push(`/claims/${claim.id}/${nextTab.path}`);
      }).catch((error) => {
        if (onError) {
          onError(error);
        }

        defaultErrorHandler(error);
      });
    }
  };

  return (
    <Button type="primary" onClick={save}>
      {label}
      <span className="ml-2"><ArrowRightOutlined /></span>
    </Button>
  );
};

NextStep.defaultProps = {
  label: 'Save & next',
  action: undefined,
  onError: undefined
};

NextStep.propTypes = {
  label: PropTypes.string,
  action: PropTypes.func,
  onError: PropTypes.func
};

export default NextStep;
