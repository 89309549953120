import React, { Component } from 'react';
import { ClaimsContext } from 'rmg-react-utils';
import Claims from '.';

class ClaimsContainer extends Component {
  componentDidMount() {
    const { getClaims } = this.context;

    getClaims({ page: 'all' });
  }

  render() {
    return (
      <Claims />
    );
  }
}

ClaimsContainer.contextType = ClaimsContext;

export default ClaimsContainer;
