import React from 'react';
import CreateStepsContextProvider from 'contexts/CreateStepsContext';
import CreateClaim from '.';

const CreateClaimContainer = () => (
  <CreateStepsContextProvider>
    <CreateClaim />
  </CreateStepsContextProvider>
);

export default CreateClaimContainer;
