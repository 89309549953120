import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { ClaimContext } from 'rmg-react-utils';
import ClaimDocumentList from 'layouts/documents/ClaimDocumentList';
import NextStep from '../WizardFooter/NextStep';

const DocumentsModal = ({ visible, setVisible }) => {
  const { claim } = useContext(ClaimContext);

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Documents generated"
      visible={visible}
      footer={null}
      onCancel={closeModal}
      centered
    >
      <p>
        Please download the following documents and arrange for their completion by the relevant
        parties. These completed and signed documents will need to be uploaded in Step 4. In the
        meantime you can continue providing information for your claim
      </p>

      <ClaimDocumentList claim={claim} />

      <Row justify="space-between" className="mt-3">
        <Col span={12} className="text-left">
          <Link to="/documents" className="ant-btn">
            See all documents
          </Link>
        </Col>
        <Col span={12} className="text-right">
          <NextStep label="Next" onError={closeModal} />
        </Col>
      </Row>
    </Modal>
  );
};

DocumentsModal.defaultProps = {
  visible: false
};

DocumentsModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired
};

export default DocumentsModal;
