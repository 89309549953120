import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import { t } from 'rmg-react-utils';

const CommentsIcon = ({ commentsCount }) => {
  if (commentsCount === 0) {
    return null;
  }

  return (
    <Tooltip title={t('item.hasComments')}>
      <CommentOutlined className="ml-2" />
    </Tooltip>
  );
};

CommentsIcon.propTypes = {
  commentsCount: PropTypes.number.isRequired
};

export default CommentsIcon;
