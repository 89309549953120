import React, { useState, useContext } from 'react';
import { CurrentAccountContext } from 'rmg-react-utils';
import SectionsForm from '../../components/SectionsForm';
import WizardFooter from '../WizardFooter';
import DocumentsModal from './DocumentsModal';
import CreateClaimFormContainer from '../CreateClaimFormContainer';

const ClaimSections = () => {
  const [modalVisible, setVisible] = useState(false);
  const { hasUploads } = useContext(CurrentAccountContext);

  // If there's an uploads tab key then we want the next step button to show the modal, otherwise
  // just go to the next step as usual.
  const action = hasUploads ? () => setVisible(true) : undefined;

  return (
    <>
      <CreateClaimFormContainer>
        <DocumentsModal visible={modalVisible} setVisible={setVisible} />

        <SectionsForm />
      </CreateClaimFormContainer>

      <WizardFooter previousStep="claim-details" action={action} />
    </>
  );
};

export default ClaimSections;
