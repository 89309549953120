import React from 'react';
import ItemsTable from 'layouts/claim/components/ItemsTable';
import WizardFooter from '../WizardFooter';
import CreateClaimFormContainer from '../CreateClaimFormContainer';

const ClaimSections = () => (
  <>
    <CreateClaimFormContainer>
      <ItemsTable />
    </CreateClaimFormContainer>

    <WizardFooter previousStep="bank-details" />
  </>
);

export default ClaimSections;
