import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClaimContext, Loader } from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';
import ClaimDetails from './show';
import CreateClaimContainer from './create/container';

const ClaimContainer = () => {
  const { claim, getClaim } = useContext(ClaimContext);
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getClaim(id).catch(defaultErrorHandler);
    }
  }, [getClaim, loaded, id]);

  if (!claim.id) {
    return <Loader />;
  }

  if (claim.status === 'draft' || claim.justSubmitted) {
    return (
      <CreateClaimContainer />
    );
  }

  return (
    <ClaimDetails />
  );
};

export default ClaimContainer;
