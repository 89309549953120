import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ClaimContext } from 'rmg-react-utils';
import ArrowBack from 'components/ArrowBack';

const BackLink = ({ step }) => {
  const { claim } = useContext(ClaimContext);
  return (
    <Link to={`/claims/${claim.id}/${step}`} className="ant-btn ant-btn-link px-0" disabled={!step}>
      <ArrowBack text="Back" />
    </Link>
  );
};

BackLink.defaultProps = {
  step: null
};

BackLink.propTypes = {
  step: PropTypes.string
};

export default BackLink;
