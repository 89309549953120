import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import { FilesFormatter } from 'rmg-react-utils';

const DocumentsSection = ({ documents }) => {
  if (documents && documents.length > 0) {
    return (
      <FilesFormatter documents={documents} />
    );
  }

  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No documents in this section" />
  );
};

DocumentsSection.defaultProps = {
  documents: []
};

DocumentsSection.propTypes = {
  documents: PropTypes.array
};

export default DocumentsSection;
