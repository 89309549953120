import { setTranslations, ApiClient } from 'rmg-react-utils';

const client = new ApiClient(process.env.REACT_APP_BACKEND_HOST);

export const getTranslations = () => {
  client.get('/translations.json').then((response) => {
    setTranslations(response.data);
  });
};

export default { getTranslations };
