import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import ClaimPersonalDetails from 'layouts/claim/create/PersonalDetails';
import ClaimSections from 'layouts/claim/create/Sections';
import ClaimDetails from 'layouts/claim/create/ClaimDetails';
import ClaimUploads from 'layouts/claim/create/Uploads';
import ClaimBankDetails from 'layouts/claim/create/BankDetails';
import ClaimItems from 'layouts/claim/create/Items';
import NewItem from 'layouts/Items/New';
import EditItem from 'layouts/Items/Edit';
import ShowItem from 'layouts/Items/Show';
import ClaimDeclaration from 'layouts/claim/create/Declaration';
import ClaimThankYou from 'layouts/claim/create/ThankYou';
import { CreateStepsContext } from 'contexts/CreateStepsContext';
import PrivateRoute from './PrivateRoute';

const ClaimFormRouter = () => {
  const { claimTab, setCurrentPath } = useContext(CreateStepsContext);
  const { pathname } = useLocation();

  // Updates the selected tab when the location changes
  useEffect(() => {
    const currentPath = _.last(pathname.split('/'));
    setCurrentPath(currentPath);
  }, [pathname, setCurrentPath]);

  return (
    <Switch>
      <PrivateRoute exact path="/claims/:id/personal-details" component={ClaimPersonalDetails} />
      <PrivateRoute exact path="/claims/:id/sections" component={ClaimSections} />
      <PrivateRoute exact path="/claims/:id/claim-details" component={ClaimDetails} />
      <PrivateRoute exact path="/claims/:id/uploads" component={ClaimUploads} />
      <PrivateRoute exact path="/claims/:id/bank-details" component={ClaimBankDetails} />
      <PrivateRoute exact path="/claims/:id/items" component={ClaimItems} />
      <PrivateRoute exact path="/claims/:claimId/sections/:sectionId/items/new" component={NewItem} />
      <PrivateRoute exact path="/claims/:claimId/sections/:sectionId/items/:id" component={ShowItem} />
      <PrivateRoute exact path="/claims/:claimId/sections/:sectionId/items/:id/edit" component={EditItem} />
      <PrivateRoute exact path="/claims/:id/declaration" component={ClaimDeclaration} />
      <PrivateRoute exact path="/claims/:id/thank-you" component={ClaimThankYou} />

      {
        claimTab.path && (
          <Redirect exact from="/claims/:id" to={`/claims/:id/${claimTab.path}`} />
        )
      }
    </Switch>
  );
};

export default ClaimFormRouter;
