import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

const InternalServerError = () => {
  const { goBack } = useHistory();

  return (
    <section className="error-page text-center">
      <h1 className="mt-4 mb-2">500</h1>
      <p className="mb-4">We are sorry but something went wrong.</p>

      <Button type="primary" onClick={goBack}>Go back</Button>
    </section>
  );
};

export default InternalServerError;
