import React, { useContext } from 'react';
import { Row } from 'antd';
import { t, CurrentAccountContext, ClaimContext } from 'rmg-react-utils';
import FormGroup from 'components/FormGroup';
import ConditionalDescription from 'components/ConditionalDescription';
import SectionDivider from 'components/SectionDivider';
import Title from 'components/Title';

const ClaimDetails = () => {
  const { claim } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);

  return (
    <>
      <Title text="Claim Details" />

      <FormGroup>
        <SectionDivider title={t(`${claimStructure.type}.sections.claim_information`)} />

        <dl className="claim-descriptions">
          <Row>
            {
              claimStructure.basicDetails
                && claimStructure.basicDetails.fields.map((field) => (
                  <ConditionalDescription model={claim} field={field} key={field.key} />
                ))
            }
          </Row>
        </dl>
      </FormGroup>

      {
        claimStructure.claimDetails && claimStructure.claimDetails.map((section) => (
          <FormGroup key={section.key}>
            <SectionDivider title={section.label} />

            <dl className="claim-descriptions">
              <Row>
                {
                  section.fields.map((field) => (
                    <ConditionalDescription model={claim} field={field} key={field.key} />
                  ))
                }
              </Row>
            </dl>
          </FormGroup>
        ))
      }

      <Title text="Sections" />

      <FormGroup>
        {
          claimStructure.sections && claimStructure.sections.map((section) => (
            !!claim[section.key] && (
              <React.Fragment key={section.key}>
                <SectionDivider title={section.label} />

                <dl className="claim-descriptions">
                  <Row>
                    {
                      section.fields.map((field) => (
                        <ConditionalDescription
                          model={claim[section.key]}
                          field={field}
                          key={field.key}
                        />
                      ))
                    }
                  </Row>
                </dl>
              </React.Fragment>
            )
          ))
        }
      </FormGroup>
    </>
  );
};

export default ClaimDetails;
