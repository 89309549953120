import React, { useContext } from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';
import { ClaimContext } from 'rmg-react-utils';

const ThankYou = () => {
  const { claim } = useContext(ClaimContext);

  return (
    <section className="box p-0">
      <Result
        status="success"
        title="Thank you for submitting your claim"
        subTitle="Your claim will be reviewed shortly."
        extra={[
          // TODO: Force page reload to clear justSubmitted flag in claim
          <a href={`/claims/${claim.id}`} className="ant-btn ant-btn-primary" key="show-details">Show details</a>,
          <Link to="/claims" className="ant-btn" key="claim-details">Return to list</Link>
        ]}
      />
    </section>
  );
};

export default ThankYou;
