import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Table, Grid, message } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { paramCase } from 'change-case';
import RequiresActionIcon from 'layouts/claim/components/RequiresActionIcon';
import {
  t,
  DynamicFormatter,
  DateFormatter,
  CurrentAccountContext,
  ClaimsContext,
  Loader
} from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';
import RowDetails from './RowDetails';
import { mobileTitleRule } from '../../utils/responsive';
import DeleteClaimIcon from './DeleteClaimIcon';
import NewCommentsIcon from './components/NewCommentsIcon';

const { Column } = Table;

const ClaimsTable = ({ claims, expandable, expanded }) => {
  const { destroyClaim, loading } = useContext(ClaimsContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const currentBreakpoint = Grid.useBreakpoint();

  const buildColumnId = useCallback((columnName) => (
    `claims-${columnName}`
  ), []);

  useEffect(() => {
    if (!loaded && currentBreakpoint.xs) {
      setLoaded(true);

      const style = document.createElement('style');
      document.head.appendChild(style);

      style.sheet.insertRule(
        mobileTitleRule(buildColumnId('id'), t('activerecord.attributes.claim.id')), 0
      );

      claimStructure.frontofficeTable.forEach((itemsTableColumn) => {
        style.sheet.insertRule(
          mobileTitleRule(buildColumnId(itemsTableColumn.key), itemsTableColumn.label), 0
        );
      });

      style.sheet.insertRule(
        mobileTitleRule(buildColumnId('status'), t('activerecord.attributes.claim.status')), 0
      );
      style.sheet.insertRule(
        mobileTitleRule(buildColumnId('created-at'), t('activerecord.attributes.claim.createdAt')), 0
      );
      style.sheet.insertRule(
        mobileTitleRule(buildColumnId('outcome'), t('activerecord.attributes.claim.outcome')), 0
      );
    }
  }, [claimStructure.frontofficeTable, loaded, currentBreakpoint.xs, buildColumnId]);

  if (loading) {
    return <Loader />;
  }

  const onRow = (record) => ({
    onClick: () => {
      if (!currentBreakpoint.xs) {
        history.push(`/claims/${record.id}`);
      }
    }
  });

  const expandableConfig = () => {
    if (currentBreakpoint.xs) {
      return {};
    }

    return {
      expandedRowRender: (record) => (<RowDetails claim={record} />),
      rowExpandable: () => expandable,
      indentSize: 0,
      defaultExpandAllRows: expanded
    };
  };

  const onDestroyClaim = (claimId) => (
    destroyClaim(claimId).then(() => {
      message.success(t('claim.destroyed', { id: claimId }));
    }).catch(defaultErrorHandler)
  );

  return (
    <Table
      dataSource={claims}
      rowKey={(record) => record.id}
      loading={loading}
      className="claims-table"
      rowClassName={(record) => (record.requiresAction ? 'action-required' : '')}
      pagination={false}
      onRow={onRow}
      expandable={expandableConfig()}
    >
      <Column
        className={buildColumnId('id')}
        title={t('activerecord.attributes.claim.id')}
        dataIndex="id"
      />
      {
        claimStructure.frontofficeTable.map((itemsTableColumn) => (
          <Column
            className={buildColumnId(itemsTableColumn.key)}
            title={itemsTableColumn.label}
            key={itemsTableColumn.key}
            render={(record) => (
              <DynamicFormatter model={record} field={itemsTableColumn} />
            )}
          />
        ))
      }
      <Column
        className={buildColumnId('status')}
        title={t('activerecord.attributes.claim.status')}
        dataIndex="frontofficeStatus"
        render={(_text, record) => (
          <span className={`status-tag status-${paramCase(record.frontofficeStatus)}`}>
            { t(`claim.frontoffice_statuses.${record.frontofficeStatus}`) }
          </span>
        )}
      />
      <Column
        className={buildColumnId('created-at')}
        title={t('activerecord.attributes.claim.createdAt')}
        dataIndex="createdAt"
        render={(text) => <DateFormatter value={text} />}
      />
      <Column
        className={buildColumnId('outcome')}
        title={t('activerecord.attributes.claim.outcome')}
        dataIndex="outcome"
        render={(text) => t(`claim.outcomes.${text}`)}
      />
      <Column
        title=""
        className="text-right"
        responsive={['sm']}
        render={(record) => (
          <>
            <RequiresActionIcon showIcon={record.requiresAction} label={t('claim.requiresAction')} />

            <DeleteClaimIcon record={record} destroyClaim={onDestroyClaim} />

            <NewCommentsIcon claim={record} />
          </>
        )}
      />
      {
        currentBreakpoint.xs && (
          <Column
            className="table-col-mobile-actions"
            render={(record) => (
              <>
                <RequiresActionIcon showIcon={record.requiresAction} label={t('claim.requiresAction')} />

                <DeleteClaimIcon record={record} destroyClaim={onDestroyClaim} />

                <Link to={`/claims/${record.id}`} className="ant-btn ant-btn-secondary ml-3">
                  <span>
                    {
                      record.status === 'draft' ? t('claim.continue_submitting') : t('item.show-multiple')
                    }
                  </span>

                  <ArrowRightOutlined />
                </Link>
              </>
            )}
          />
        )
      }

    </Table>
  );
};

ClaimsTable.defaultProps = {
  expandable: false,
  expanded: false
};

ClaimsTable.propTypes = {
  claims: PropTypes.array.isRequired,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool
};

export default ClaimsTable;
