import React, { useContext, useEffect } from 'react';
import { CurrentAccountContext, ClaimContext, ConditionalField, Loader } from 'rmg-react-utils';
import FormGroup from 'components/FormGroup';
import { Row, Col } from 'antd';
import DocumentsSectionTitle from './DocumentsSectionTitle';
import DocumentUpload from './DocumentUpload';

const UploadsForm = () => {
  const { claim, getDocuments, documentsLoaded } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);

  useEffect(() => {
    getDocuments();
  }, []);

  if (!documentsLoaded) {
    return <Loader />;
  }

  if (!claimStructure.documents) {
    return null;
  }

  return (
    <FormGroup title="Mandatory documents">
      <Row justify="space-between" gutter={{ xs: 15, md: 100 }}>
        {
          claimStructure.documents.map((field) => (
            <ConditionalField model={claim} field={field} key={field.key}>
              <Col xs={{ span: 12 }} md={{ span: 8 }} key={field.key}>
                <DocumentsSectionTitle claim={claim} kind={field.key} />
                <DocumentUpload kind={field.kind} />
              </Col>
            </ConditionalField>
          ))
        }
      </Row>
    </FormGroup>
  );
};

export default UploadsForm;
