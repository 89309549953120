import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Badge } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import { t } from 'rmg-react-utils';

const NewCommentsIcon = ({ claim }) => {
  if (claim.claimantUnreadCommentsCount === 0) {
    return null;
  }

  return (
    <Tooltip title={t('item.hasComments')}>
      <Badge size="small" count={claim.claimantUnreadCommentsCount} offset={[4, 0]}>
        <CommentOutlined className="ml-2" />
      </Badge>
    </Tooltip>
  );
};

NewCommentsIcon.propTypes = {
  claim: PropTypes.object.isRequired
};

export default NewCommentsIcon;
