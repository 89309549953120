import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { t } from 'rmg-react-utils';
import AddItemBtn from './AddItemBtn';

const AddItemBtnContainer = ({ claimId, sectionKey, canCreateItems }) => {
  if (canCreateItems) {
    return <AddItemBtn claimId={claimId} sectionKey={sectionKey} />;
  }

  return (
    <Tooltip title={t('item.cannotCreateItem')}>
      <span>
        <AddItemBtn claimId={claimId} sectionKey={sectionKey} disabled />
      </span>
    </Tooltip>
  );
};

AddItemBtnContainer.propTypes = {
  claimId: PropTypes.number.isRequired,
  sectionKey: PropTypes.string.isRequired,
  canCreateItems: PropTypes.bool.isRequired
};

export default AddItemBtnContainer;
