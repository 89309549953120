import React, { useContext, useEffect } from 'react';
import { ClaimContext, Loader } from 'rmg-react-utils';
import Title from 'components/Title';
import SectionDivider from 'components/SectionDivider';
import { defaultErrorHandler } from 'utils/bugsnag';

const PolicyInformation = () => {
  const { cover, getCover, coverLoaded } = useContext(ClaimContext);

  useEffect(() => {
    getCover().catch(defaultErrorHandler);
  }, []);

  if (!coverLoaded) {
    return (
      <Loader />
    );
  }

  return (
    <section>
      <Title text="Policy information" />

      <SectionDivider title={cover.name} />

      {
        cover.files.map((file) => (
          <p className="mb-2" key={file.url}>
            <a href={file.url} target="_blank" rel="noopener noreferrer">
              {file.name}
            </a>
          </p>
        ))
      }
    </section>
  );
};

export default PolicyInformation;
