import React, { useContext } from 'react';
import { Button, Alert, Row, Col, Typography } from 'antd';
import { useHistory } from 'react-router';
import { ClaimContext, If, t } from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';

const SubmitMoreInfo = () => {
  const { claim, sendAction } = useContext(ClaimContext);
  const history = useHistory();

  const save = (action) => {
    sendAction(action).then(() => {
      history.push(`/claims/${claim.id}/more-info-thank-you`);
    }).catch(defaultErrorHandler);
  };

  const alertContent = (
    <Row justify="space-between" align="middle">
      <Col className="text-left" xs={{ span: 16 }} sm={{ span: 20 }}>
        <Typography.Text type="warning">
          Some information is missing in this claim. Please check your inbox and make the necessary
          updates. Once all information is complete, submit your claim again.
        </Typography.Text>
      </Col>
      <Col className="text-right" xs={{ span: 8 }} sm={{ span: 4 }}>
        <If condition={claim.permissions.canSubmitMoreInfo}>
          <Button type="primary" onClick={() => save('submit_more_info')}>
            {t('claim.actions.submitMoreInfo')}
          </Button>
        </If>
        <If condition={claim.permissions.canSubmitVerificationInfo}>
          <Button type="primary" onClick={() => save('submit_verification_info')}>
            {t('claim.actions.submitVerificationInfo')}
          </Button>
        </If>
      </Col>
    </Row>
  );

  if (!claim.permissions.canSubmitMoreInfo && !claim.permissions.canSubmitVerificationInfo) {
    return null;
  }

  return (
    <Alert type="warning" message={alertContent} className="mb-4" />
  );
};

export default SubmitMoreInfo;
