import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { t, CurrentAccountContext, ClaimsContext, If, AuthContext } from 'rmg-react-utils';
import { Link } from 'react-router-dom';
import Title from 'components/Title';
import MainMenu from 'layouts/dashboard/MainMenu';
import ClaimsTable from './Table';

const Claims = () => {
  const { user } = useContext(AuthContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const { claims } = useContext(ClaimsContext);
  const [openClaims, setOpenClaims] = useState([]);
  const [draftClaims, setDraftClaims] = useState([]);
  const [closedClaims, setClosedClaims] = useState([]);

  useEffect(() => {
    const open = [];
    const draft = [];
    const closed = [];

    claims.forEach((claim) => {
      if (claim.status === 'draft') {
        draft.push(claim);
      } else if (claim.status === 'closed') {
        closed.push(claim);
      } else {
        open.push(claim);
      }
    });

    setOpenClaims(open);
    setDraftClaims(draft);
    setClosedClaims(closed);
  }, [claims]);

  return (
    <Row>
      <MainMenu selected="claims" />

      <Col xs={{ span: 24 }} sm={{ span: 19, offset: 1 }} md={{ span: 18, offset: 1 }}>
        <Row>
          <Col span={12}>
            <Title text="Open" />
          </Col>
          <Col span={12} className="text-right">
            <If condition={user.permissions.createPopulatedClaim}>
              <Link to="/claims/new?populated=true" className="ant-btn ant-btn-lg mr-2">
                Create pre-populated injury
              </Link>
            </If>

            <Link to="/claims/new" className="ant-btn ant-btn-lg ant-btn-primary">
              { t(`${claimStructure.type}.new`) }
            </Link>
          </Col>
        </Row>

        <ClaimsTable claims={openClaims} expandable expanded />

        <Title text="Drafts" />

        <ClaimsTable claims={draftClaims} />

        <Title text="Closed" />

        <ClaimsTable claims={closedClaims} expandable />
      </Col>
    </Row>
  );
};

export default Claims;
