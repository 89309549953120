import React, { useContext } from 'react';
import { Form, Input, Row, Col, Divider } from 'antd';
import { ClaimContext } from 'rmg-react-utils';
import FormGroup from 'components/FormGroup';
import PersonalDetailsExtraForm from 'layouts/claim/components/PersonalDetailsExtraForm';
import BasicDetailsForm from 'layouts/claim/components/BasicDetailsForm';
import PersonalDetailsForm from 'layouts/claim/components/PersonalDetailsForm';
import WizardFooter from '../WizardFooter';
import CreateClaimFormContainer from '../CreateClaimFormContainer';

const ClaimPersonalDetails = () => {
  const { claim } = useContext(ClaimContext);

  return (
    <>
      <CreateClaimFormContainer>
        <FormGroup title="Your details" count="1">
          <Form layout="vertical" initialValues={claim.user}>
            <Row gutter={30}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item name="name" label="Name">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item name="email" label="Email">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <PersonalDetailsExtraForm />
        </FormGroup>

        <Divider />

        <PersonalDetailsForm />

        <BasicDetailsForm headerNumber="4" />
      </CreateClaimFormContainer>

      <WizardFooter />
    </>
  );
};

export default ClaimPersonalDetails;
