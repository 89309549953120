import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'rmg-react-utils';
import { snakeCase } from 'change-case';
import { Row, Col } from 'antd';
import ArrowDown from 'components/ArrowDown';
import clubDeclaration from '../../images/club_declaration.png';
import physicianReport from '../../images/physician_report.png';
import employerStatement from '../../images/employer_statement.png';
import privacyConsent from '../../images/privacy_consent.png';

const documentPreviews = {
  club_declaration: clubDeclaration,
  physician_report: physicianReport,
  employer_statement: employerStatement,
  privacy_consent: privacyConsent
};

const Document = ({ claimId, kind }) => {
  const documentType = () => snakeCase(kind.replace('Documents', ''));

  const documentUrl = () => (
    `${process.env.REACT_APP_BACKEND_HOST}/claims/${claimId}/${documentType()}.pdf`
  );

  return (
    <div className="mt-3 mb-3">
      {t(`activerecord.attributes.claim.${kind}`)}
      <div className="document-preview mb-2">
        <div className="document-preview-image">
          <img
            style={{ width: '100%' }}
            src={documentPreviews[documentType()]}
            alt={t(`activerecord.attributes.claim.${kind}`)}
          />
        </div>
      </div>

      <a href={documentUrl()} target="_blank" rel="noopener noreferrer" className="ant-btn ant-btn-outline ant-btn-block">
        <Row justify="space-between">
          <Col span={12} className="text-left">
            Download
          </Col>
          <Col span={12} className="text-right">
            <ArrowDown />
          </Col>
        </Row>
      </a>
    </div>
  );
};

Document.propTypes = {
  claimId: PropTypes.number.isRequired,
  kind: PropTypes.string.isRequired
};

export default Document;
