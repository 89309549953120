import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Upload, message } from 'antd';
import { ALLOWED_MIME_TYPES, ClaimContext, isUnprocessableEntity } from 'rmg-react-utils';
import UploadIcon from 'components/Icons/Upload';
import { defaultErrorHandler } from 'utils/bugsnag';

const UPLOADING_STATUS = 'uploading';
const DONE_STATUS = 'done';

const convertDocumentToFile = (document) => (
  {
    uid: document.id,
    name: document.file.name,
    status: DONE_STATUS,
    url: document.file.url,
    kind: document.kind
  }
);

const DocumentUpload = ({ kind }) => {
  const { claim, deleteDocument, addDocument, documents } = useContext(ClaimContext);
  const [fileList, setFileList] = useState(
    documents.documents.filter((document) => document.kind === kind)
      .map((document) => convertDocumentToFile(document))
  );

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);

    deleteDocument(file).catch(defaultErrorHandler);
  };

  const beforeUpload = (file) => {
    const uid = Date.now();
    const newFile = { uid, name: file.name, status: UPLOADING_STATUS };
    let newList = [...fileList, newFile];

    setFileList(newList);

    addDocument({ file, kind, documentableId: claim.id, documentableType: 'Claim' }).then((response) => {
      const { file: responseFile } = response.data;
      message.success(`${responseFile.name} file uploaded successfully`);

      newList = newList.map((entry) => {
        if (entry.uid === uid) {
          return convertDocumentToFile(response.data);
        }

        return entry;
      });

      setFileList(newList);
    }).catch((error) => {
      if (isUnprocessableEntity(error)) {
        const anotherList = [...newList];
        _.remove(anotherList, (entry) => (entry.uid === newFile.uid));
        setFileList(anotherList);

        message.error('File upload failed: file type is invalid');
      }

      defaultErrorHandler(error);
    });

    return false;
  };

  return (
    <div className="upload-square">
      <Upload.Dragger
        fileList={fileList}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        accept={ALLOWED_MIME_TYPES.join(',')}
      >
        <p className="ant-upload-drag-icon">
          <UploadIcon color="secondary" />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Upload.Dragger>
    </div>
  );
};

DocumentUpload.defaultProps = {
  kind: 'support'
};

DocumentUpload.propTypes = {
  kind: PropTypes.string
};

export default DocumentUpload;
