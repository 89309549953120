import React, { useContext } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { ClaimContext } from 'rmg-react-utils';
import ShowClaimDetails from 'layouts/claim/show/ClaimDetails';
import EditClaimDetails from 'layouts/claim/edit/ClaimDetails';
import ClaimItems from 'layouts/claim/show/Items';
import NewItem from 'layouts/Items/New';
import EditItem from 'layouts/Items/Edit';
import ShowItem from 'layouts/Items/Show';
import NewSection from 'layouts/Sections/New';
import ShowPersonalDetails from 'layouts/claim/show/PersonalDetails';
import EditPersonalDetails from 'layouts/claim/edit/PersonalDetails';
import ShowClaimDocuments from 'layouts/claim/show/Documents';
import EditClaimDocuments from 'layouts/claim/edit/Documents';
import PolicyInformation from 'layouts/claim/show/PolicyInformation';
import MoreInfoThankYou from 'layouts/claim/show/MoreInfoThankYou';
import Inbox from 'layouts/claim/show/Inbox';
import Timeline from 'layouts/claim/show/Timeline';
import PrivateRoute from './PrivateRoute';

const DashboardRouter = () => {
  const { claim } = useContext(ClaimContext);

  return (
    <Switch>
      <PrivateRoute exact path="/claims/:id/items">
        <ClaimItems />
      </PrivateRoute>

      <PrivateRoute exact path="/claims/:claimId/sections/:sectionId/new">
        <NewSection />
      </PrivateRoute>

      <PrivateRoute exact path="/claims/:claimId/sections/:sectionId/items/new">
        <NewItem />
      </PrivateRoute>

      <PrivateRoute exact path="/claims/:claimId/sections/:sectionId/items/:id">
        <ShowItem />
      </PrivateRoute>

      <PrivateRoute exact path="/claims/:claimId/sections/:sectionId/items/:id/edit">
        <EditItem />
      </PrivateRoute>

      <PrivateRoute exact path="/claims/:id/claim-details">
        { claim.permissions.update ? <EditClaimDetails /> : <ShowClaimDetails /> }
      </PrivateRoute>

      <PrivateRoute exact path="/claims/:id/personal-details">
        { claim.permissions.update ? <EditPersonalDetails /> : <ShowPersonalDetails /> }
      </PrivateRoute>

      {
        claim.permissions.showDocuments && (
          <PrivateRoute exact path="/claims/:id/documents">
            { claim.permissions.update ? <EditClaimDocuments /> : <ShowClaimDocuments /> }
          </PrivateRoute>
        )
      }

      <PrivateRoute exact path="/claims/:id/more-info-thank-you">
        <MoreInfoThankYou />
      </PrivateRoute>

      <PrivateRoute exact path="/claims/:id/policy-info">
        <PolicyInformation />
      </PrivateRoute>

      <PrivateRoute exact path="/claims/:id/inbox">
        <Inbox />
      </PrivateRoute>

      <PrivateRoute exact path="/claims/:id/timeline">
        <Timeline />
      </PrivateRoute>

      <Redirect exact from="/claims/:id" to="/claims/:id/items" />
    </Switch>
  );
};

export default DashboardRouter;
