import React, { useContext } from 'react';
import { Form, Row, Col } from 'antd';
import {
  ConditionalField,
  buildDynamicForm,
  ClaimContext,
  CurrentAccountContext,
  DynamicFieldWithErrors
} from 'rmg-react-utils';
import FormGroup from 'components/FormGroup';
import { defaultErrorHandler } from 'utils/bugsnag';

const BankDetailsForm = () => {
  const { claim, updateClaim } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const [form] = Form.useForm();

  if (!claimStructure.bankAccountDetails) {
    return null;
  }

  return (
    <FormGroup title="Bank details">
      <Form
        layout="vertical"
        form={form}
        initialValues={buildDynamicForm(claim, claimStructure.bankAccountDetails)}
      >
        <Row gutter={30}>
          {
            claimStructure.bankAccountDetails.map((field) => (
              <ConditionalField model={claim} field={field} key={field.key}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <DynamicFieldWithErrors
                    model={claim}
                    field={field}
                    updateModel={(values) => updateClaim(values).catch(defaultErrorHandler)}
                    form={form}
                  />
                </Col>
              </ConditionalField>
            ))
          }
        </Row>
      </Form>
    </FormGroup>
  );
};

export default BankDetailsForm;
