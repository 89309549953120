import React, { useContext } from 'react';
import { Form, Row, Col, Button, message } from 'antd';
import { AuthContext, isUnprocessableEntity, PasswordWithErrors, t } from 'rmg-react-utils';
import FormGroup from 'components/FormGroup';
import { defaultErrorHandler } from 'utils/bugsnag';

const Account = () => {
  const { user, clearErrors, updatePassword } = useContext(AuthContext);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    updatePassword(values).then(() => {
      form.resetFields();
      message.success('User updated');
    }).catch((error) => {
      if (isUnprocessableEntity(error)) {
        message.error(t('globals.errorsInForm'));
      }

      defaultErrorHandler(error);
    });
  };

  return (
    <div className="container">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 18, offset: 3 }} lg={{ span: 10, offset: 7 }}>
            <FormGroup title="Change password">
              <PasswordWithErrors name="currentPassword" label="Current password" errors={user.errors} clearErrors={clearErrors} />

              <PasswordWithErrors name="password" label="Password" errors={user.errors} clearErrors={clearErrors} />

              <PasswordWithErrors name="passwordConfirmation" label="Password confirmation" errors={user.errors} clearErrors={clearErrors} />
            </FormGroup>

            <div className="text-right">
              <Button type="primary" htmlType="submit">Save</Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Account;
