import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined } from '@ant-design/icons';

const ArrowBack = ({ text }) => (
  <>
    <span className="mr-2"><ArrowLeftOutlined /></span>
    {text}
  </>
);

ArrowBack.propTypes = {
  text: PropTypes.string.isRequired
};

export default ArrowBack;
