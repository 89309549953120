import React from 'react';
import FormGroup from 'components/FormGroup';
import WizardFooter from '../WizardFooter';
import CreateClaimFormContainer from '../CreateClaimFormContainer';

const ClaimDeclaration = () => (
  <>
    <CreateClaimFormContainer>
      <FormGroup title="Acknowledge and review">
        <p>
          I hereby authorise any hospital, physician, medical practitioner, medical specialist or
          any other person who has attended to me and / or employer of mine, past or present, to
          furnish Gow-Gates and / or its representatives with any and all information with respect
          to any sickness or injury, medical history, consults, prescriptions or treatment, copies
          of all hospital or medical records and copies of all records of employers including
          verification of my earnings. I acknowledge that any personal information that I have or
          will provide to Gow-Gates is necessary for and will be used in processing, assessing,
          investigation or review of this claim.
        </p>

        <p>
          I hereby authorise Gow-Gates and / or its representatives and consent to Gow-Gates
          and / or its representatives and its authorised agent to disclose any personal information
          to or receive it from an investigator, assessor, surveyor, accountant, supplier, health
          service provider, appointed / authorised broker, account broker, and / or broker of the
          entire / body corporate / organisation insured (Insured), State or Federal Authority,
          lawyer, another insurer or reinsurer (local or overseas), reinsurance broker, witness or
          another party to the claim. I will be provided with the opportunity to access my personal
          information (some restrictions and costs may apply). In respect of any complaint I may
          have regarding my personal information, I can contact the Gow-Gates office.
        </p>

        <p>
          I agree that digital evidence of this authorisation shall be considered as effective and
          valid. I do solemnly and sincerely declare that the foregoing particulars are true and
          correct in every detail.
        </p>

        <p>
          <strong>
            Warning: Persons found to have lodged a fraudulent claim are liable for prosecution.
          </strong>
        </p>
      </FormGroup>
    </CreateClaimFormContainer>

    <WizardFooter previousStep="items" label="Agree & Submit" />
  </>
);

export default ClaimDeclaration;
