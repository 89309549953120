import React, { useContext, useState, useEffect } from 'react';
import { Form, Button, Alert } from 'antd';
import { Link, Redirect, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { PasswordWithErrors, ApiContext, AuthContext, Loader } from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';
import AuthContainer from './container';

const Confirmation = () => {
  const { confirm, user } = useContext(AuthContext);
  const { isLoggedIn, errorMessage } = useContext(ApiContext);
  const { search } = useLocation();
  const [initState, setInitState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // When initializing it will try to confirm the user.
  //   - when registering through the frontoffice users set a password and confirmation will be done
  //     by just clicking the link.
  //   - if the user is created in the backoffice by an admin, he will have to define a password
  useEffect(() => {
    if (isLoggedIn) {
      setInitState('loaded');
      return;
    }

    if (initState) {
      return;
    }

    setInitState('loading');

    confirm({}, queryString.parse(search).confirmation_token, true, true).catch((error) => {
      setInitState('loaded');

      defaultErrorHandler(error);
    });
  }, [initState, confirm, search]);

  const onFinish = (values) => {
    setLoading(true);
    confirm(values, queryString.parse(search).confirmation_token).catch((error) => {
      setLoading(false);

      defaultErrorHandler(error);
    });
  };

  if (user.errors && user.errors.confirmationToken) {
    return (
      <AuthContainer title="Confirm email">
        <Alert message="Error" description="Confirmation token is invalid" type="error" showIcon className="mb-4" />

        <p>
          <Link to="/resend-confirmation">
            Resend confirmation instructions
          </Link>
        </p>

        <p>
          <Link to="/login">
            Log in
          </Link>
        </p>
      </AuthContainer>
    );
  }

  if (initState !== 'loaded') {
    return <Loader />;
  }

  if (errorMessage) {
    return (
      <AuthContainer title="Confirm account">
        <Link to="/login">
          Log in
        </Link>
      </AuthContainer>
    );
  }

  if (isLoggedIn) {
    return (
      <Redirect to="/" />
    );
  }

  return (
    <AuthContainer title="Confirm account">
      <Form form={form} onFinish={onFinish} className="login-form">
        <PasswordWithErrors name="password" placeholder="Password" errors={user.errors} />

        <PasswordWithErrors
          name="passwordConfirmation"
          placeholder="Password confirmation"
          errors={user.errors}
        />

        <Button type="primary" htmlType="submit" className="w-100 mb-4" loading={loading}>
          Set my password
        </Button>
      </Form>
    </AuthContainer>
  );
};

export default Confirmation;
