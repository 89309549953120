import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Row, Col } from 'antd';
import { ApiContext, AuthContext, CurrentAccountContext } from 'rmg-react-utils';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Box from 'components/Box';
import IEBanner from 'layouts/shared/IEBanner';

const AuthContainer = ({ title, children }) => {
  const history = useHistory();
  const location = useLocation();
  const { clearUserErrors } = useContext(AuthContext);
  const { isLoggedIn, errorMessage, redirectPath, setErrorMessage } = useContext(ApiContext);
  const { account } = useContext(CurrentAccountContext);

  useEffect(() => {
    if (isLoggedIn) {
      history.push({ pathname: redirectPath });
    }
  }, [isLoggedIn]);

  // Actions to to at every location change
  useEffect(() => {
    if (history.action === 'PUSH') {
      setErrorMessage('');
      clearUserErrors();
    }
  }, [location.pathname, history.action, setErrorMessage]);

  return (
    <>
      <IEBanner />
      <div className="container">
        <Row>
          <Col
            xs={{ span: 24 }}
            md={{ span: 18, offset: 3 }}
            lg={{ span: 14, offset: 5 }}
            xl={{ span: 12, offset: 6 }}
          >
            <div className="auth-container">
              <Box>
                <div className="text-center mb-4">
                  <Link to="/">
                    <img src={account.logo && account.logo.url} alt={account.name} className="logo" />
                  </Link>
                </div>

                <div className="auth-form">
                  <h1>{ title }</h1>

                  {
                    errorMessage
                      && (
                        <Alert message="Error" description={errorMessage} type="error" showIcon className="mb-4" />
                      )
                  }

                  { children }
                </div>
              </Box>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

AuthContainer.defaultProps = {
  title: ''
};

AuthContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default AuthContainer;
