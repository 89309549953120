import React from 'react';
import PropTypes from 'prop-types';

const Upload = ({ color, width, height }) => {
  const viewBox = `0 0 ${width} ${height}`;
  const className = `line-fill-${color}`;

  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={className} d="M40.854 22.4427L35.5157 10.6598C35.3332 10.3322 34.9773 10.1357 34.6123 10.1731H30.0314C29.5021 10.1731 29.0824 10.613 29.0824 11.1465C29.0824 11.6893 29.5112 12.1198 30.0314 12.1198H33.9918L38.4267 21.8999H32.988C30.5242 21.8905 28.2793 23.3598 27.2664 25.6621C26.5273 27.2438 24.976 28.2546 23.2604 28.2546H17.7305C16.024 28.2546 14.4636 27.2438 13.7245 25.6621C12.7116 23.3598 10.4667 21.8811 8.00289 21.8999H2.67371L7.92077 12.1198H10.9686C11.4979 12.1198 11.9177 11.6799 11.9177 11.1465C11.9177 10.6037 11.4888 10.1731 10.9686 10.1731H7.34587C6.99911 10.1357 6.6706 10.3135 6.48809 10.613L0.191631 22.4427C0.0821277 22.5456 0.00912531 22.686 0 22.8357V35.0117C0.0730025 37.7539 2.2357 39.9438 4.90941 40H36.0906C38.7643 39.9532 40.927 37.7539 41 35.0117V22.8357C40.9818 22.6954 40.9361 22.5643 40.854 22.4427Z" />
      <path className={className} d="M19.8294 22.6392C20.1671 23.0136 20.742 23.0416 21.107 22.6954C21.1252 22.6767 21.1435 22.6579 21.1617 22.6392L27.6498 15.9382C27.9692 15.5077 27.878 14.89 27.4582 14.5718C27.1206 14.3098 26.6552 14.3098 26.3175 14.5718L21.4537 19.5601V0.973327C21.4537 0.43051 21.0249 0 20.5047 0C19.9846 0 19.5557 0.439869 19.5557 0.973327V19.5601L14.6919 14.5718C14.2721 14.2443 13.6699 14.3379 13.3596 14.7684C13.1041 15.1146 13.1041 15.592 13.3596 15.9382L19.8294 22.6392Z" />
    </svg>
  );
};

Upload.defaultProps = {
  width: 41,
  height: 40,
  color: 'default'
};

Upload.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  color: PropTypes.oneOf(['default', 'primary', 'secondary'])
};

export default Upload;
