import React, { useContext, useState } from 'react';
import {
  ClaimContext,
  CurrentAccountContext,
  t,
  ConditionalField,
  buildDynamicForm,
  DynamicFieldWithErrors,
  isUnprocessableEntity
} from 'rmg-react-utils';
import { Form } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import SpanLink from 'components/SpanLink';

const UpdateBankDetails = () => {
  const { claim: originalClaim, sendAction } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const [isModalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  const [claim, setClaim] = useState({ ...originalClaim });

  if (!originalClaim.permissions.updateBankDetails) {
    return null;
  }

  const updateBankDetails = () => {
    sendAction('update_bank_details', form.getFieldsValue(), false).then(() => {
      setModalVisible(false);
    }).catch((error) => {
      if (isUnprocessableEntity(error)) {
        setClaim(error.response.data);
      }
    });
  };

  const updateField = (values) => {
    setClaim({ ...claim, ...values });
  };

  return (
    <>
      <SpanLink onClick={() => setModalVisible(true)}>
        {t('claim.editBankDetails')}
      </SpanLink>

      <Modal
        title={t('claim.editBankDetails')}
        visible={isModalVisible}
        onOk={updateBankDetails}
        onCancel={() => setModalVisible(false)}
        okText={t('globals.save')}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={buildDynamicForm(claim, claimStructure.bankAccountDetails)}
        >
          {
            claimStructure.bankAccountDetails.map((field) => (
              <ConditionalField model={claim} field={field} key={field.key}>
                <DynamicFieldWithErrors
                  model={claim}
                  field={field}
                  form={form}
                  updateModel={updateField}
                />
              </ConditionalField>
            ))
          }
        </Form>
      </Modal>
    </>
  );
};

export default UpdateBankDetails;
