import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Affix, Row, Col } from 'antd';

const BottomBar = ({ leftContent, rightContent }) => {
  const [isFixed, setFixed] = useState(false);

  const className = `box wizard-footer${isFixed ? ' wizard-footer-fixed' : ''}`;

  return (
    <Affix offsetBottom={0} onChange={(affixed) => setFixed(affixed)}>
      <div className={className}>
        <Row>
          <Col xs={{ span: 12 }} md={{ span: 11, offset: 1 }} className="text-left">
            {leftContent}
          </Col>

          <Col xs={{ span: 12 }} md={{ span: 11 }} className="text-right">
            {rightContent}
          </Col>
        </Row>
      </div>
    </Affix>
  );
};

BottomBar.defaultProps = {
  leftContent: null,
  rightContent: null
};

BottomBar.propTypes = {
  leftContent: PropTypes.node,
  rightContent: PropTypes.node
};

export default BottomBar;
