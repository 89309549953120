import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  ApiContextProvider,
  AppContextProvider,
  CurrentAccountContextProvider,
  TripsContextProvider,
  AuthContextProvider
} from 'rmg-react-utils';
import Main from 'Main';

import 'react-quill/dist/quill.snow.css';
import './stylesheets/app.less';

const tenant = window.location.hostname.replace(process.env.REACT_APP_DOMAIN, '');

const themeUrl = `${process.env.REACT_APP_BACKEND_HOST}/themes/${tenant}.css`;
const authTokenKey = `_rmg_${tenant}_jwt`;

const App = () => (
  <>
    <link rel="stylesheet" media="all" href={themeUrl} />

    <ApiContextProvider authTokenKey={authTokenKey} domain={process.env.REACT_APP_DOMAIN}>
      <Router>
        <AuthContextProvider>
          <CurrentAccountContextProvider>
            <AppContextProvider>
              {/* probably this only needs to be in claim pages */}
              <TripsContextProvider>
                <Main />
              </TripsContextProvider>
            </AppContextProvider>
          </CurrentAccountContextProvider>
        </AuthContextProvider>
      </Router>
    </ApiContextProvider>
  </>
);

export default App;
