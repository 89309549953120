import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'antd';
import { ClaimContext, t } from 'rmg-react-utils';
import Box from 'components/Box';
import { defaultErrorHandler } from 'utils/bugsnag';

const AddSection = ({ sectionStructure }) => {
  const { saveSection } = useContext(ClaimContext);
  const [loading, isLoading] = useState(false);

  const addSection = () => {
    isLoading(true);
    saveSection({ type: sectionStructure.key }).catch(defaultErrorHandler).finally(() => {
      isLoading(false);
    });
  };

  return (
    <Box className="claim-section">
      <Row justify="space-between" align="middle">
        <Col xs={{ span: 24 }} md={{ span: 20 }}>
          <span className="text-muted">
            { t('section.claimant_empty', { section: sectionStructure.label }) }
          </span>
        </Col>

        <Col xs={{ span: 24 }} md={{ span: 4 }} className="claim-section-btn">
          <Button type="secondary" onClick={() => addSection(sectionStructure)} loading={loading}>
            Claim
          </Button>
        </Col>
      </Row>
    </Box>
  );
};

AddSection.propTypes = {
  sectionStructure: PropTypes.object.isRequired
};

export default AddSection;
