import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider } from 'antd';

const FormGroup = ({ title, count, divider, children }) => (
  <section className="form-group">
    {
      title && (
        <header className="form-group-header">
          <h3>
            {
              count && (
                <Typography.Text type="secondary">
                  {count}
                  <span>. </span>
                </Typography.Text>
              )
            }

            <span>{ title }</span>
          </h3>
        </header>
      )
    }

    { children }

    {divider && <Divider />}
  </section>
);

FormGroup.defaultProps = {
  count: null,
  divider: false,
  title: null
};

FormGroup.propTypes = {
  title: PropTypes.string,
  count: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  children: PropTypes.node.isRequired,
  divider: PropTypes.bool
};

export default FormGroup;
