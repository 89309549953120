import React, { useContext } from 'react';
import { DateFormatter, ClaimContext, CurrentAccountContext } from 'rmg-react-utils';
import FormGroup from 'components/FormGroup';
import ConditionalDescription from 'components/ConditionalDescription';
import { Row, Col } from 'antd';
import SectionDivider from 'components/SectionDivider';
import Title from 'components/Title';
import UpdateBankDetails from './UpdateBankDetails';

const ClaimClaimant = () => {
  const { claim } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);

  return (
    <>
      <Title text="Personal Details" />

      <FormGroup>
        <SectionDivider title="Contact details" />

        <dl className="claim-descriptions">
          <Row gutter={30}>
            {
              claimStructure.personalDetailsExtra
                && claimStructure.personalDetailsExtra.fields.map((field) => (
                  <ConditionalDescription model={claim} field={field} key={field.key} />
                ))
            }

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <dt>Claimant name</dt>
              <dd>{claim.user.name}</dd>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <dt>email</dt>
              <dd>{claim.user.email}</dd>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <dt>Claimant DOB</dt>
              <dd><DateFormatter value={claim.user.birthDate} /></dd>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <dt>Street address</dt>
              <dd>{claim.user.address}</dd>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <dt>Suburb</dt>
              <dd>{claim.user.suburb}</dd>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <dt>Post code</dt>
              <dd>{claim.user.postcode}</dd>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <dt>country</dt>
              <dd>{claim.user.country}</dd>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <dt>Home phone</dt>
              <dd>{claim.user.phone}</dd>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <dt>Mobile phone</dt>
              <dd>{claim.user.mobilePhone}</dd>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <dt>Work phone</dt>
              <dd>{claim.user.workPhone}</dd>
            </Col>
          </Row>
        </dl>
      </FormGroup>

      <FormGroup>
        <SectionDivider title="Bank payment details" />

        <dl className="ant-row">
          {
            claimStructure.bankAccountDetails && claimStructure.bankAccountDetails.map((field) => (
              <ConditionalDescription model={claim} field={field} key={field.key} />
            ))
          }
        </dl>

        <UpdateBankDetails />
      </FormGroup>
    </>
  );
};

export default ClaimClaimant;
