import React from 'react';
import { isIE } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons';

const IEBanner = () => {
  if (!isIE) {
    return null;
  }

  return (
    <div className="ie-banner">
      <div className="container">
        <WarningOutlined className="mr-2" />
        Your browser is not fully supported and you may experience some issues.
        We strongly recommend that you&nbsp;
        <Link to="/browserlist">update your browser.</Link>
      </div>
    </div>
  );
};

export default IEBanner;
