import React, { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CurrentAccountContext, ClaimContext } from 'rmg-react-utils';

export const CreateStepsContext = createContext();

export const tabMapping = [
  {
    key: 'personal_details',
    path: 'personal-details',
    action: 'add_personal_details'
  },
  {
    key: 'claim_details',
    path: 'claim-details',
    action: 'add_claim_details'
  },
  {
    key: 'section_details',
    path: 'sections',
    action: 'add_section_details'
  },
  {
    key: 'uploads',
    path: 'uploads',
    action: 'add_uploads'
  },
  {
    key: 'bank_details',
    path: 'bank-details',
    action: 'add_bank_details'
  },
  {
    key: 'item_details',
    path: 'items',
    action: 'add_item_details'
  },
  {
    key: 'declaration',
    path: 'declaration',
    action: 'submit'
  },
  {
    key: 'thank_you',
    path: 'thank-you',
    action: ''
  }
];

const CreateStepsContextProvider = ({ children }) => {
  const { claim } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);

  const [currentPath, setCurrentPath] = useState('');
  // Index of the selected tab
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  // Object of the selected tab
  const [currentTab, setCurrentTab] = useState({});
  // Object of the next tab
  const [nextTab, setNextTab] = useState({});
  // Index of the tab corresponding to the current status of the claim
  const [claimTabIndex, setClaimTabIndex] = useState(0);
  // object of the tab corresponding to the current status of the claim
  const [claimTab, setClaimTab] = useState({});

  // This useEffect handle tab change.
  useEffect(() => {
    // If path is empty, return
    if (!currentPath) {
      return;
    }

    const tab = _.find(tabMapping, { path: currentPath });

    // if tab is not found, return
    if (!tab) {
      return;
    }

    setCurrentTab(tab);

    // finds index of tab in claim structure
    const currentIndex = _.findIndex(claimStructure.createTabs, { key: tab.key });
    setCurrentTabIndex(currentIndex);

    // sets next tab. If is is the last tab in claim structure, next will be thank you
    if (currentIndex < claimStructure.createTabs.length - 1) {
      const nextKey = claimStructure.createTabs[currentIndex + 1].key;
      setNextTab(_.find(tabMapping, { key: nextKey }));
    } else {
      setNextTab(_.find(tabMapping, { key: 'thank_you' }));
    }
  }, [currentPath, claimStructure.createTabs]);

  useEffect(() => {
    setClaimTab(_.find(tabMapping, { key: claim.createStatus }));
    setClaimTabIndex(_.findIndex(claimStructure.createTabs, { key: claim.createStatus }));
  }, [claim.createStatus, claimStructure.createTabs]);

  const getTabByIndex = (number) => {
    const { key } = claimStructure.createTabs[number];

    return _.find(tabMapping, { key });
  };

  const isTabDisabled = (index) => (index > claimTabIndex);

  const getTab = (key) => _.find(tabMapping, { key });

  const value = {
    currentTab,
    nextTab,
    claimTab,
    getTab,
    getTabByIndex,
    isTabDisabled,
    currentTabIndex,
    setCurrentPath
  };

  return (
    <CreateStepsContext.Provider value={value}>
      {children}
    </CreateStepsContext.Provider>
  );
};

CreateStepsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CreateStepsContextProvider;
