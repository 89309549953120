import React from 'react';
import ClaimDetailsForm from '../../components/ClaimDetailsForm';
import SectionsForm from '../../components/SectionsForm';
import BasicDetailsForm from '../../components/BasicDetailsForm';

const ClaimDetails = () => (
  <>
    <BasicDetailsForm />

    <ClaimDetailsForm />

    <SectionsForm />
  </>
);

export default ClaimDetails;
