import React from 'react';
import { ClaimsContextProvider } from 'rmg-react-utils';
import ClaimsContainer from './container';

const Claims = () => (
  <ClaimsContextProvider>
    <ClaimsContainer />
  </ClaimsContextProvider>
);

export default Claims;
