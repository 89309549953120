import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { ApiContext } from 'rmg-react-utils';

const PrivateRoute = ({ component, children, path, exact, strict, sensitive }) => {
  const { isLoggedIn, setRedirectPath } = useContext(ApiContext);
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      setRedirectPath(location.pathname);
    }
  }, [isLoggedIn, setRedirectPath, location.pathname]);

  if (isLoggedIn) {
    if (children) {
      return (
        <Route path={path} exact={exact} strict={strict} sensitive={sensitive}>
          {children}
        </Route>
      );
    }

    return (
      <Route
        path={path}
        exact={exact}
        strict={strict}
        sensitive={sensitive}
        component={component}
      />
    );
  }

  return (
    <Redirect to="/login" />
  );
};

PrivateRoute.defaultProps = {
  component: null,
  children: null
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  children: PropTypes.node,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  sensitive: PropTypes.bool
};

PrivateRoute.defaultProps = {
  exact: false,
  strict: false,
  sensitive: false
};

export default PrivateRoute;
