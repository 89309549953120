import React from 'react';
import PropTypes from 'prop-types';
import { CloseCircleFilled } from '@ant-design/icons';
import { FileIcon } from 'rmg-react-utils';

const File = ({ url, type, name, onRemove }) => {
  const content = (
    <div className="file-field mb-2 mr-2">
      <FileIcon type={type} />
      {name}
      {
        onRemove && (
          <CloseCircleFilled className="remove-file" onClick={onRemove} />
        )
      }
    </div>
  );

  if (url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" className="file-field-link">
        { content }
      </a>
    );
  }

  return content;
};

File.defaultProps = {
  url: null,
  type: '',
  onRemove: null,
  onDestroy: null
};

File.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  onDestroy: PropTypes.func
};

export default File;
