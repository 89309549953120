import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CurrentAccountContext, ClaimContext } from 'rmg-react-utils';
import FormGroup from 'components/FormGroup';
import Title from 'components/Title';
import ItemDetails from './components/Details';

const ShowItem = () => {
  const { claim, getItem } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const [item, setItem] = useState(null);
  const [structure, setStructure] = useState({});
  const { sectionId, id } = useParams();

  useEffect(() => {
    const section = claim[sectionId];

    const itemToFind = section.items.find((i) => (`${i.id}` === id));

    // When deleting the item this function will run because the claim is updated.
    // but at this point there is no item anymore, therefore itemToFind will be null
    if (!itemToFind) {
      return;
    }

    getItem(itemToFind, sectionId).then((response) => {
      setItem({ ...response.data });
    });
  }, [sectionId, id, claim]);

  useEffect(() => {
    setStructure(claimStructure.sections.find((s) => s.key === sectionId));
  }, [claimStructure, sectionId]);

  if (item && structure) {
    const title = `Expense #${item.id}`;

    return (
      <>
        <Title text={title} />

        <FormGroup>
          <ItemDetails item={item} sectionStructure={structure} />
        </FormGroup>
      </>
    );
  }

  return null;
};

export default ShowItem;
