import React, { Component } from 'react';
import { ClaimsContext } from 'rmg-react-utils';
import DocumentsList from './List';

class DocumentsContainer extends Component {
  componentDidMount() {
    const { getClaims } = this.context;

    getClaims({ include_sections: true });
  }

  render() {
    return (
      <DocumentsList />
    );
  }
}

DocumentsContainer.contextType = ClaimsContext;

export default DocumentsContainer;
