import React, { useContext } from 'react';
import _ from 'lodash';
import { Form, Row, Col } from 'antd';
import {
  buildDynamicForm,
  ConditionalField,
  stringToDate,
  DatePickerWithErrors,
  ClaimContext,
  CurrentAccountContext,
  AuthContext,
  DynamicFieldWithErrors,
  SelectWithErrors,
  t
} from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';

const PersonalDetailsExtraForm = () => {
  const { claim, updateClaim } = useContext(ClaimContext);
  const { updateUser } = useContext(AuthContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const [form] = Form.useForm();

  if (!claimStructure.personalDetailsExtra) {
    return null;
  }

  const genders = _.map(t('user.genders'), (value, key) => ({ key: key === 'true', value }));

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        ...buildDynamicForm(claim, claimStructure.personalDetailsExtra.fields),
        birthDate: stringToDate(claim.user.birthDate),
        gender: claim.user.gender
      }}
    >
      <Row gutter={30}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <DatePickerWithErrors
            label="Date of birth"
            name="birthDate"
            errors={claim.user.errors}
            updateModel={(values) => updateUser(values).catch(defaultErrorHandler)}
          />
        </Col>

        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <SelectWithErrors
            label="Gender"
            name="gender"
            errors={claim.user.errors}
            updateModel={(values) => updateUser(values).catch(defaultErrorHandler)}
            options={genders}
          />
        </Col>

        {
          claimStructure.personalDetailsExtra.fields.map((field) => (
            <Col xs={{ span: 24 }} md={{ span: 12 }} key={field.key}>
              <ConditionalField model={claim} field={field} key={field.key}>
                <DynamicFieldWithErrors
                  model={claim}
                  field={field}
                  updateModel={(values) => updateClaim(values).catch(defaultErrorHandler)}
                  form={form}
                />
              </ConditionalField>
            </Col>
          ))
        }
      </Row>
    </Form>
  );
};

export default PersonalDetailsExtraForm;
