import React from 'react';
import PropTypes from 'prop-types';
import { ClubDescription, TripDescription, ConditionalField, DynamicFormatter } from 'rmg-react-utils';
import { Col } from 'antd';

const ConditionalDescription = ({ model, field }) => {
  const components = {
    club: ClubDescription,
    trip: TripDescription
  };

  if (field.type === 'component') {
    const DynamicComponent = components[field.componentType];
    return (
      <Col xs={{ span: 24 }} md={{ span: 12 }}>
        <DynamicComponent model={model} />
      </Col>
    );
  }

  return (
    <ConditionalField model={model} field={field}>
      <Col xs={{ span: 24 }} md={{ span: 12 }}>
        <dt>{field.label}</dt>
        <dd>
          <DynamicFormatter model={model} field={field} />
        </dd>
      </Col>
    </ConditionalField>
  );
};

ConditionalDescription.propTypes = {
  model: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired
};

export default ConditionalDescription;
