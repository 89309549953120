import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import { CurrentAccountContext, t } from 'rmg-react-utils';
import { PlusOutlined } from '@ant-design/icons';

const AddItemDropdown = ({ claim, disabled }) => {
  const { claimStructure } = useContext(CurrentAccountContext);
  const [activeItems, setActiveItems] = useState([]);

  useEffect(() => {
    const items = claimStructure.sections.filter((sectionStructure) => (
      claim.permissions.createItems[sectionStructure.key]
    ));

    setActiveItems(items);
  }, [claimStructure.sections]);

  const menu = (
    <Menu>
      {
        activeItems.map((sectionStructure) => (
          <Menu.Item key={sectionStructure.key}>
            <Link to={`/claims/${claim.id}/sections/${sectionStructure.key}/items/new`}>
              {sectionStructure.label}
            </Link>
          </Menu.Item>
        ))
      }
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']} disabled={disabled}>
      <Button type="secondary" ghost icon={<PlusOutlined />} disabled={disabled}>
        {t('item.add')}
      </Button>
    </Dropdown>
  );
};

AddItemDropdown.defaultProps = {
  disabled: false
};

AddItemDropdown.propTypes = {
  disabled: PropTypes.bool,
  claim: PropTypes.object.isRequired
};

export default AddItemDropdown;
