import React from 'react';
import PropTypes from 'prop-types';

const Box = ({ borderRadius, className, children }) => {
  const style = {
    borderRadius
  };

  return (
    <section className={`box ${className}`} style={style}>
      { children }
    </section>
  );
};

Box.defaultProps = {
  borderRadius: 0,
  className: ''
};

Box.propTypes = {
  children: PropTypes.node.isRequired,
  borderRadius: PropTypes.number,
  className: PropTypes.string
};

export default Box;
