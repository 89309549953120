import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import { FilesUploaderWithErrors, QuillWithErrors, ClaimContext, isUnprocessableEntity } from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';

const NewComment = ({ onSubmit }) => {
  const { claim, addComment } = useContext(ClaimContext);
  const [comment, setComment] = useState({});
  const [count, setCount] = useState(1);

  if (!claim.permissions.canAddComment) {
    return null;
  }

  const [form] = Form.useForm();

  const onFinish = () => {
    form.validateFields().then((values) => {
      addComment({ ...values, commentableType: 'Claim', commentableId: claim.id }).then(() => {
        onSubmit();
        form.resetFields();
        setCount(count + 1);
      }).catch((error) => {
        if (isUnprocessableEntity(error)) {
          setComment({ ...comment, errors: { ...error.response.data.errors } });
        }

        defaultErrorHandler(error);
      });
    });
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <QuillWithErrors name="text" errors={comment.errors} />

      <FilesUploaderWithErrors
        label="Attachments"
        name="documents"
        errors={comment.errors}
        form={form}
        model={comment}
        reload={count}
      />

      <div className="text-right">
        <Button type="primary" htmlType="submit">
          Add comment
        </Button>
      </div>
    </Form>
  );
};

NewComment.defaultProps = {
  onSubmit: () => {}
};

NewComment.propTypes = {
  onSubmit: PropTypes.func
};

export default NewComment;
