import React, { useContext } from 'react';
import { t, CurrentAccountContext } from 'rmg-react-utils';
import { CreateStepsContext } from 'contexts/CreateStepsContext';

const StepHelper = () => {
  const { claimStructure } = useContext(CurrentAccountContext);
  const { currentTab } = useContext(CreateStepsContext);

  if (!currentTab) {
    return null;
  }

  const text = t(`${claimStructure.type}.createStatuses.${currentTab.key}.helper`);

  /* eslint-disable-next-line react/no-danger */
  return (<div className="mb-4" dangerouslySetInnerHTML={{ __html: text }} />);
};

export default StepHelper;
