import React, { useContext, useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CurrentAccountContext, ClaimContext, ConditionalField, DynamicFieldWithErrors, t, isUnprocessableEntity } from 'rmg-react-utils';
import Title from 'components/Title';
import BottomBar from 'components/BottomBar';
import ArrowBack from 'components/ArrowBack';
import { defaultErrorHandler } from 'utils/bugsnag';

const NewItem = () => {
  const { claim, saveSection } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const [structure, setStructure] = useState(null);
  const { sectionId, claimId } = useParams();
  const [form] = Form.useForm();
  const [section, setSection] = useState({});
  const history = useHistory();

  const onFinish = (values) => {
    saveSection({ ...values, type: sectionId, validate: true }).then((response) => {
      const { data: updatedClaim } = response;

      // TODO: attention when merging the 'improve load times' branch
      if (updatedClaim.permissions.createItems[structure.key]) {
        history.push(`/claims/${claimId}/sections/${sectionId}/items/new`);
      } else {
        history.push(`/claims/${claimId}/items`);
      }
    }).catch((error) => {
      if (isUnprocessableEntity(error)) {
        setSection(error.response.data);
      }

      defaultErrorHandler(error);
    });
  };

  const onChangeField = (params) => {
    setSection({ ...section, ...params });
  };

  useEffect(() => {
    setStructure(claimStructure.sections.find((s) => s.key === sectionId));
  }, [claimStructure, sectionId]);

  if (!structure) {
    return null;
  }

  return (
    <>
      <Title text={structure.label} />
      <p className="mb-4">{ t('section.noItemsYet', { section: structure.label }) }</p>

      {/* eslint-disable-next-line react/no-danger */}
      <div className="mb-4" dangerouslySetInnerHTML={{ __html: structure.sectionHelper }} />

      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{}}>
        <Row gutter={30}>
          {
            structure.fields && structure.fields.map((field) => (
              <ConditionalField model={section} field={field} key={field.key}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <DynamicFieldWithErrors
                    model={section}
                    field={field}
                    updateModel={onChangeField}
                    form={form}
                  />
                </Col>
              </ConditionalField>
            ))
          }
        </Row>

        <BottomBar
          leftContent={(
            <Link to={`/claims/${claim.id}/items`} className="ant-btn ant-btn-link px-0">
              <ArrowBack text={t('globals.cancel')} />
            </Link>
          )}
          rightContent={(
            <Button type="primary" htmlType="submit">{t('globals.next')}</Button>
          )}
        />
      </Form>
    </>
  );

  // return 'oi';
};

export default NewItem;
