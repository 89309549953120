import React from 'react';
import WizardFooter from '../WizardFooter';
import BankDetailsForm from '../../components/BankDetailsForm';
import CreateClaimFormContainer from '../CreateClaimFormContainer';

const ClaimBankDetails = () => (
  <>
    <CreateClaimFormContainer>
      <BankDetailsForm />
    </CreateClaimFormContainer>

    <WizardFooter previousStep="uploads" />
  </>
);

export default ClaimBankDetails;
