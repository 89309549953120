import React from 'react';
import PropTypes from 'prop-types';

const SpanLink = ({ onClick, className, children }) => (
  <span role="link" onClick={onClick} onKeyUp={onClick} tabIndex={-1} className={className}>
    { children }
  </span>
);

SpanLink.defaultProps = {
  className: null
};

SpanLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default SpanLink;
