import React, { useContext } from 'react';
import { CurrentAccountContext } from 'rmg-react-utils';
import SectionContainer from './SectionContainer';

const SectionsForm = () => {
  const { claimStructure } = useContext(CurrentAccountContext);

  if (!claimStructure.sections) {
    return null;
  }

  return (
    <>
      {
        claimStructure.sections.map((sectionStructure, index) => (
          <SectionContainer
            key={sectionStructure.key}
            sectionStructure={sectionStructure}
            count={index + 1}
          />
        ))
      }
    </>
  );
};

export default SectionsForm;
