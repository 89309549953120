import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CurrentAccountContext, ClaimContext } from 'rmg-react-utils';
import ItemForm from './components/Form';

const EditItem = () => {
  const { claim } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);
  const [item, setItem] = useState(null);
  const [structure, setStructure] = useState({});
  const { sectionId, id } = useParams();

  useEffect(() => {
    const section = claim[sectionId];

    setItem(section.items.find((i) => (`${i.id}` === id)));
  }, [sectionId, id, claim]);

  useEffect(() => {
    setStructure(claimStructure.sections.find((s) => s.key === sectionId));
  }, [claimStructure, sectionId]);

  if (item && structure) {
    return (
      <ItemForm currentItem={item} structure={structure.items} sectionKey={structure.key} />
    );
  }

  return null;
};

export default EditItem;
