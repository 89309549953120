import React, { useContext, useEffect, useState } from 'react';
import { CurrentAccountContext, Loader } from 'rmg-react-utils';
import AppRouter from 'router';
import { defaultErrorHandler } from 'utils/bugsnag';

const Main = () => {
  const { account, claimStructure, getCurrentAccount } = useContext(CurrentAccountContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getCurrentAccount().catch(defaultErrorHandler);
    }
  }, [getCurrentAccount, loaded]);

  // If acount and structure are not loaded, the app cannot load
  if (!account.id || !claimStructure.type) {
    return <Loader />;
  }

  return (
    <AppRouter />
  );
};

export default Main;
