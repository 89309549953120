import React from 'react';
import PropTypes from 'prop-types';
import SectionDivider from 'components/SectionDivider';
import ClaimDocumentList from './ClaimDocumentList';

const DocumentSection = ({ claim }) => (
  <>
    <SectionDivider title={`Claim #${claim.id}`} />

    <ClaimDocumentList claim={claim} />
  </>
);

DocumentSection.propTypes = {
  claim: PropTypes.object.isRequired
};

export default DocumentSection;
