import React, { useContext, useState } from 'react';
import { Form, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext, InputWithErrors } from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';
import AuthContainer from './container';

const ResendConfirmation = () => {
  const { resendConfirmation, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = (values) => {
    setLoading(true);
    resendConfirmation(values).then(() => {
      history.push('/login');
    }).catch((error) => {
      setLoading(false);

      defaultErrorHandler(error);
    });
  };

  return (
    <AuthContainer title="Resend confirmation instructions">
      <Form form={form} onFinish={onFinish} className="login-form">
        <InputWithErrors name="email" placeholder="Email" errors={user.errors} type="email" />

        <Button type="primary" htmlType="submit" className="w-100 mb-4" loading={loading}>
          Resend confirmation instructions
        </Button>

        <p>
          <Link to="/login">
            Log in
          </Link>
        </p>
      </Form>
    </AuthContainer>
  );
};

export default ResendConfirmation;
