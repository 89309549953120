import React, { useContext, useState } from 'react';
import { Form, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { PasswordWithErrors, CurrentAccountContext, AuthContext, InputWithErrors } from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';
import AuthContainer from './container';

const Signup = () => {
  const { account } = useContext(CurrentAccountContext);
  const { user, signup } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = (values) => {
    setLoading(true);

    signup(values).then(() => {
      history.push('/thankyou');
    }).catch((error) => {
      setLoading(false);

      defaultErrorHandler(error);
    });
  };

  return (
    <AuthContainer title="Sign up">
      <Form form={form} onFinish={onFinish} className="login-form">
        {
          account.needsUserName && (
            <>
              <InputWithErrors name="firstName" placeholder="First name" errors={user.errors} />
              <InputWithErrors name="lastName" placeholder="Last name" errors={user.errors} />
            </>
          )
        }

        <InputWithErrors name="email" placeholder="Email" errors={user.errors} type="email" />
        <PasswordWithErrors name="password" placeholder="Password" errors={user.errors} />
        <PasswordWithErrors
          name="passwordConfirmation"
          placeholder="Password Confirmation"
          errors={user.errors}
        />

        <Button type="primary" htmlType="submit" className="w-100 mb-4" loading={loading}>
          Sign up
        </Button>
      </Form>

      <p>
        <span>Already have an account? </span>
        <Link to="/login">Log in here</Link>
      </p>
    </AuthContainer>
  );
};

export default Signup;
