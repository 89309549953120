import React, { useContext, useEffect } from 'react';
import { t, ClaimContext, CurrentAccountContext, Loader } from 'rmg-react-utils';
import SectionDivider from 'components/SectionDivider';
import FormGroup from 'components/FormGroup';
import Title from 'components/Title';
import { defaultErrorHandler } from 'utils/bugsnag';
import DocumentsSection from './DocumentsSection';

const ClaimDocuments = () => {
  const { documents, getDocuments, documentsLoaded } = useContext(ClaimContext);
  const { claimStructure } = useContext(CurrentAccountContext);

  useEffect(() => {
    getDocuments().catch(defaultErrorHandler);
  }, []);

  if (!claimStructure.documents) {
    return null;
  }

  if (!documentsLoaded) {
    return (
      <Loader />
    );
  }

  return (
    <>
      <Title text="Documents" />

      {
        documents.documents.map((document) => (
          <FormGroup key={document.id}>
            <SectionDivider title={t(`document.kinds.${document.kind}`)} />
            <DocumentsSection documents={[document]} />
          </FormGroup>
        ))
      }

      {
        documents.importedSupportDocuments && documents.importedSupportDocuments.length > 0 && (
          <FormGroup>
            <SectionDivider title="Support documents" />
            <DocumentsSection documents={documents.importedSupportDocuments} />
          </FormGroup>
        )
      }

      {
        documents.importedResponseDocuments && documents.importedResponseDocuments.length > 0 && (
          <FormGroup>
            <SectionDivider title="Response documents" />
            <DocumentsSection documents={documents.importedResponseDocuments} />
          </FormGroup>
        )
      }
    </>
  );
};

export default ClaimDocuments;
