import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

const CreateClaimFormContainer = ({ children }) => (
  <div className="create-claim-form">
    <Row>
      <Col xs={{ span: 24 }} sm={{ span: 20, offset: 2 }} md={{ span: 22, offset: 1 }}>
        { children }
      </Col>
    </Row>
  </div>
);

CreateClaimFormContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default CreateClaimFormContainer;
