import React from 'react';
import { ClaimsContextProvider } from 'rmg-react-utils';
import DocumentsContainer from './container';

const Documents = () => (
  <ClaimsContextProvider>
    <DocumentsContainer />
  </ClaimsContextProvider>
);

export default Documents;
