import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Empty, Upload, message, Button, Popconfirm } from 'antd';
import { UploadOutlined, LoadingOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { If, ClaimContext, isUnprocessableEntity, ALLOWED_MIME_TYPES } from 'rmg-react-utils';
import SectionDivider from 'components/SectionDivider';
import { defaultErrorHandler } from 'utils/bugsnag';

const ItemDocuments = ({ item }) => {
  const { addDocument, deleteDocument } = useContext(ClaimContext);
  const [isUploading, setUploading] = useState(false);

  const onRemove = (document) => {
    deleteDocument({ ...document, uid: document.id }).catch(defaultErrorHandler);
  };

  const beforeUpload = (file) => {
    addDocument({ file, documentableId: item.id, documentableType: 'Item' }).then((response) => {
      const { file: responseFile } = response.data;
      message.success(`${responseFile.name} file uploaded successfully`);
    }).catch((error) => {
      if (isUnprocessableEntity(error)) {
        message.error('File upload failed: file type is invalid');
      }

      defaultErrorHandler(error);
    }).finally(() => {
      setUploading(false);
    });

    return false;
  };

  const onChange = (info) => {
    if (info.file.status !== 'uploading') {
      setUploading(true);
    }
  };

  return (
    <section className="item-documents">
      <SectionDivider title="Documents" />

      <div className="item-documents-list">
        {
          item.documents.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No documents in this expense." />
          ) : (
            <>
              {
                item.documents.map((document) => (
                  <article key={document.id} className="item-document">
                    <a href={document.file.url} target="_blank" rel="noopener noreferrer">
                      {document.file.name}
                    </a>

                    <div>
                      <Popconfirm
                        title="Are you sure you want to delete this document?"
                        okType="danger"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        onConfirm={() => onRemove(document)}
                      >
                        <span role="button" className="p-1 ml-2">
                          <DeleteOutlined />
                        </span>
                      </Popconfirm>
                    </div>
                  </article>
                ))
              }
            </>
          )
        }
      </div>

      <Upload
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        onChange={onChange}
        className="mt-3"
        accept={ALLOWED_MIME_TYPES.join(',')}
      >
        <If condition={item.permissions.canUpdate}>
          <Button disabled={isUploading}>
            <UploadOutlined />
            Upload a document
          </Button>
        </If>

        {
          isUploading && (
            <span className="ml-3">
              <LoadingOutlined className="mr-2" />
              uploading
            </span>
          )
        }
      </Upload>
    </section>
  );
};

ItemDocuments.propTypes = {
  item: PropTypes.object.isRequired
};

export default ItemDocuments;
