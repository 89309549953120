import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ClaimContext } from 'rmg-react-utils';
import FormGroup from 'components/FormGroup';
import SectionForm from './SectionForm';
import AddSection from './AddSection';

const SectionContainer = ({ sectionStructure, count }) => {
  const { claim } = useContext(ClaimContext);

  if (!claim.permissions.createSections[sectionStructure.key]) {
    return null;
  }

  return (
    <FormGroup
      title={sectionStructure.label}
      key={sectionStructure.key}
      count={count}
      divider
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className="mb-4" dangerouslySetInnerHTML={{ __html: sectionStructure.sectionHelper }} />

      {
        !claim[sectionStructure.key] ? (
          <AddSection sectionStructure={sectionStructure} />
        ) : (
          <SectionForm
            section={claim[sectionStructure.key]}
            structure={sectionStructure}
          />
        )
      }
    </FormGroup>
  );
};

SectionContainer.defaultProps = {
  count: null
};

SectionContainer.propTypes = {
  sectionStructure: PropTypes.object.isRequired,
  count: PropTypes.number
};

export default SectionContainer;
