import React, { useContext } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { CurrentAccountContext } from 'rmg-react-utils';
import Claims from '../layouts/claims/ContextProvider';
import Documents from '../layouts/documents';
import ClaimContainer from '../layouts/claim/container';
import NewClaimContainer from '../layouts/claim/new/container';
import ProfileContainer from '../layouts/profile/container';
import AccountContainer from '../layouts/account/container';
import SettingsContainer from '../layouts/settings/container';
import PrivateRoute from './PrivateRoute';

const DashboardRouter = () => {
  const { hasUploads } = useContext(CurrentAccountContext);

  return (
    <Switch>
      <PrivateRoute exact path="/claims">
        <Claims />
      </PrivateRoute>

      {
        hasUploads && (
          <PrivateRoute exact path="/documents">
            <Documents />
          </PrivateRoute>
        )
      }

      <PrivateRoute path="/claims/new" component={NewClaimContainer} />
      <PrivateRoute path="/claims/:id" component={ClaimContainer} />
      <PrivateRoute path="/profile" component={ProfileContainer} />
      <PrivateRoute path="/account" component={AccountContainer} />
      <PrivateRoute path="/settings" component={SettingsContainer} />
      <Redirect exact from="/" to="/claims" />
    </Switch>
  );
};

export default DashboardRouter;
