import React, { useContext } from 'react';
import { Row, Col, Button } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { t, CurrentAccountContext, ClaimContext } from 'rmg-react-utils';
import { defaultErrorHandler } from 'utils/bugsnag';

const CreateIntro = () => {
  const { claimStructure } = useContext(CurrentAccountContext);
  const { createClaim, isClaimLoading } = useContext(ClaimContext);
  const { search } = useLocation();
  const history = useHistory();

  const handleClick = () => {
    createClaim({}, search).then((response) => {
      history.push(`/claims/${response.data.id}`);
    }).catch(defaultErrorHandler);
  };

  return (
    <Row className="mt-5">
      <Col xs={{ span: 24 }} md={{ span: 20, offset: 2 }}>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="mb-4" dangerouslySetInnerHTML={{ __html: t(`${claimStructure.type}.intro`) }} />

        <Row className="mb-4">
          <Col span={10}>
            <Link to="/claims/" className="ant-btn">
              Cancel
            </Link>
          </Col>
          <Col span={14} className="text-right">
            <Button type="primary" onClick={handleClick} loading={isClaimLoading}>
              Start creating my claim
            </Button>
          </Col>
        </Row>

        <h4>Privacy</h4>

        <p>
          <em>
            We are committed to protecting your privacy. We use the information you provide to
            advise about and assist with your Insurance needs.
          </em>
        </p>
        <p>
          <em>
            We only provide your information to the companies with whom you choose to deal (and
            their representatives). We do not trade, rent or sell your information. If you do not
            provide us with full information, we cannot properly advise you and you could breach
            your duty of disclosure. You can check the information we hold about you at any time
            by contacting our Privacy officer on (02) 8267 9999.
          </em>
        </p>
        <p>
          <em>
            Our Privacy Policy explains how we collect, use, hold, disclose and handle your
            personal information including transfer provision to necessary third parties as well
            as your rights to access and correct your personal information and how to make a
            complaint for a breach of the Australian Privacy Principles.
          </em>
        </p>
        <p>
          <em>
            For more information about our Privacy Policy please ask us for a copy and /or refer
            to our website&nbsp;
            <a href="https://www.gowgates.com.au">https://www.gowgates.com.au</a>
          </em>
        </p>
      </Col>
    </Row>
  );
};

export default CreateIntro;
