import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Table, Row, Col, Grid } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { t, DynamicFormatter, MoneyFormatter, toWeeksAndDays, ClaimContext } from 'rmg-react-utils';
import SectionDivider from 'components/SectionDivider';
import StatusFormatter from 'components/StatusFormatter';
import { mobileTitleRule } from '../../../../utils/responsive';
import RequiresActionIcon from '../RequiresActionIcon';
import CommentsIcon from '../CommentsIcon';
import AddItemBtnContainer from './AddItemBtnContainer';

const { Column } = Table;

const SectionDetails = ({ sectionStructure, showIfEmpty }) => {
  const { claim } = useContext(ClaimContext);
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const currentBreakpoint = Grid.useBreakpoint();

  // if this section has no permissions to show
  if (!claim.permissions.showSection[sectionStructure.key]) {
    return false;
  }

  // if this section was not claimed AND not showing if empty
  if (!showIfEmpty && !claim[sectionStructure.key]) {
    return null;
  }

  const onRow = (record) => ({
    onClick: () => {
      if (!currentBreakpoint.xs) {
        history.push(`/claims/${claim.id}/sections/${sectionStructure.key}/items/${record.id}`);
      }
    }
  });

  const buildColumnId = useCallback((columnName) => (
    `items-${sectionStructure.key}-${columnName}`
  ), [sectionStructure.key]);

  useEffect(() => {
    if (!loaded && currentBreakpoint.xs) {
      setLoaded(true);

      const style = document.createElement('style');
      document.head.appendChild(style);

      sectionStructure.backofficeItemsTable.forEach((itemsTableColumn) => {
        style.sheet.insertRule(
          mobileTitleRule(buildColumnId(itemsTableColumn.key), itemsTableColumn.label), 0
        );
      });

      style.sheet.insertRule(
        mobileTitleRule(buildColumnId('claimed-amount'), t('activerecord.attributes.item.claimedAmount')), 0
      );
      style.sheet.insertRule(
        mobileTitleRule(buildColumnId('claimed-time'), t('activerecord.attributes.item.claimedTime')), 0
      );
      style.sheet.insertRule(
        mobileTitleRule(buildColumnId('status'), t('activerecord.attributes.item.status')), 0
      );
    }
  }, [sectionStructure.backofficeItemsTable, loaded, currentBreakpoint.xs, buildColumnId]);

  return (
    <>
      <SectionDivider title={sectionStructure.label} />

      {/* eslint-disable-next-line react/no-danger */}
      <div className="mb-4" dangerouslySetInnerHTML={{ __html: sectionStructure.itemsHelper }} />

      <Table
        locale={{ emptyText: 'No expenses in this section' }}
        dataSource={_.get(claim, `${sectionStructure.key}.items`, [])}
        rowKey={(record) => record.id}
        pagination={false}
        onRow={onRow}
        className="items-table"
        rowClassName={(record) => (record.requiresAction ? 'action-required' : '')}
      >
        {
          sectionStructure.backofficeItemsTable.map((itemsTableColumn) => (
            <Column
              className={buildColumnId(itemsTableColumn.key)}
              title={itemsTableColumn.label}
              key={itemsTableColumn.key}
              render={(record) => (
                <DynamicFormatter model={record} field={itemsTableColumn} />
              )}
            />
          ))
        }

        {
          sectionStructure.itemCalculationType === 'time' && (
            <Column
              className={buildColumnId('claimed-time')}
              title={t('activerecord.attributes.item.claimedTime')}
              key="claimedTime"
              render={(record) => (
                toWeeksAndDays(record.claimedTime)
              )}
            />
          )
        }
        {
          sectionStructure.itemCalculationType === 'amount' && (
            <Column
              className={buildColumnId('claimed-amount')}
              title={t('activerecord.attributes.item.claimedAmount')}
              key="claimedAmount"
              render={(record) => (
                <MoneyFormatter
                  value={record.claimedAmount}
                  currency={record.claimedAmountCurrency}
                />
              )}
            />
          )
        }

        {
          claim.status !== 'draft' && (
            <Column
              className={buildColumnId('status')}
              title={t('activerecord.attributes.item.status')}
              key="actions"
              render={(_text, record) => (
                <StatusFormatter status={record.status} paidAmount={record.paidAmount} />
              )}
            />
          )
        }

        <Column
          title=""
          key="actions"
          width={130}
          className="text-right"
          responsive={['sm']}
          render={(record) => (
            <span className="text-large">
              <RequiresActionIcon showIcon={record.requiresAction} label={t('item.requiresAction')} />

              <CommentsIcon commentsCount={record.commentsCount} />
            </span>
          )}
        />
        {
          currentBreakpoint.xs && (
            <Column
              className="table-col-mobile-actions"
              render={(record) => (
                <>
                  <RequiresActionIcon showIcon={record.requiresAction} label={t('item.requiresAction')} />

                  <Link
                    to={`/claims/${claim.id}/sections/${sectionStructure.key}/items/${record.id}`}
                    className="ant-btn ant-btn-secondary ml-3"
                  >
                    <span>{t('item.show')}</span>
                    <ArrowRightOutlined />
                  </Link>
                </>
              )}
            />
          )
        }
      </Table>

      <Row align="middle">
        <Col className="text-right" span={24}>
          <AddItemBtnContainer
            claimId={claim.id}
            sectionKey={sectionStructure.key}
            canCreateItems={claim.permissions.createItems[sectionStructure.key]}
          />
        </Col>
      </Row>
    </>
  );
};

SectionDetails.defaultProps = {
  showIfEmpty: false
};

SectionDetails.propTypes = {
  sectionStructure: PropTypes.object.isRequired,
  showIfEmpty: PropTypes.bool
};

export default SectionDetails;
