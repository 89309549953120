import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import { t } from 'rmg-react-utils';
import Comment from './Comment'; // eslint-disable-line import/no-cycle

const CommentsList = ({ comments, emptyMessageKey }) => {
  if (comments.length === 0) {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t(emptyMessageKey)} />
    );
  }

  return (
    <div className="claim-inbox">
      {
        comments.map((comment) => (
          <Comment comment={comment} key={comment.id} showSubtitle />
        ))
      }
    </div>
  );
};

CommentsList.defaultProps = {
  comments: [],
  emptyMessageKey: 'claim.noComments'
};

CommentsList.propTypes = {
  comments: PropTypes.array,
  emptyMessageKey: PropTypes.string
};

export default CommentsList;
