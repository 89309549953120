import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Button, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ClaimContext, t } from 'rmg-react-utils';
import ConditionalDescription from 'components/ConditionalDescription';
import ArrowBack from 'components/ArrowBack';
import { defaultErrorHandler } from 'utils/bugsnag';
import Comments from './Comments';
import Documents from './Documents';

const ItemDetails = ({ item, sectionStructure }) => {
  const { claim, deleteItem, getClaim } = useContext(ClaimContext);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const handleClickDelete = () => {
    Modal.confirm({
      title: t('item.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      okText: t('globals.yesNo.true'),
      okType: 'danger',
      cancelText: t('globals.yesNo.false'),
      onOk() {
        setLoading(true);
        deleteItem(item, sectionStructure.key).then(() => {
          getClaim(claim.id).then(() => {
            message.success(t('item.deletedMessage'));
            history.push(`/claims/${claim.id}/items`);
          }).catch(defaultErrorHandler);
        }).catch(defaultErrorHandler);
      }
    });
  };

  return (
    <>
      <dl className="claim-descriptions">
        <Row gutter={30}>
          {
            sectionStructure.items.map((field) => (
              <ConditionalDescription model={item} field={field} key={field.key} labelSpan={12} />
            ))
          }
        </Row>
      </dl>

      <Documents item={item} />

      <Comments comments={item.comments} />

      <Row justify="space-between" align="middle" className="mt-4">
        <Col className="text-left" span={6}>
          <Link to={`/claims/${claim.id}/items`}>
            <ArrowBack text={t('globals.back')} />
          </Link>
        </Col>

        <Col className="text-right" span={18}>
          {
            item.permissions.canDestroy && (
              <Button type="danger" onClick={handleClickDelete} loading={isLoading}>{t('item.delete')}</Button>
            )
          }

          {
            item.permissions.canUpdate && (
              <Link to={`${item.id}/edit`} className="ant-btn ant-btn-primary ml-2">
                {t('globals.edit')}
              </Link>
            )
          }
        </Col>
      </Row>
    </>
  );
};

ItemDetails.propTypes = {
  item: PropTypes.object.isRequired,
  sectionStructure: PropTypes.object.isRequired
};

export default ItemDetails;
