import React from 'react';
import PropTypes from 'prop-types';
import SectionDivider from 'components/SectionDivider';
import Comment from 'layouts/claim/show/Inbox/Comment';

const ItemComments = ({ comments }) => {
  if (comments.length === 0) {
    return (
      null
    );
  }

  return (
    <section className="mb-4">
      <SectionDivider title="Comments" />

      {
        comments.map((comment) => (
          <Comment comment={comment} key={comment.id} />
        ))
      }
    </section>
  );
};

ItemComments.defaultProps = {
  comments: []
};

ItemComments.propTypes = {
  comments: PropTypes.array
};

export default ItemComments;
