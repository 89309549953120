import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = ({ title, children }) => (
  <div className="box left-menu">
    { title && <h3>{title}</h3> }

    { children }
  </div>
);

PageHeader.defaultProps = {
  title: null
};

PageHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default PageHeader;
