import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import { isUnprocessableEntity, isUnauthorized } from 'rmg-react-utils';

export const isBugsnagActive = process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUGSNAG_API_KEY;

export const notifyBugsnag = (object, info = null) => {
  if (!isBugsnagActive) {
    // eslint-disable-next-line no-console
    console.log('Notifying bugsnag:', object);
    return;
  }

  Bugsnag.notify(object, info);
};

export const setBugsnagUser = (user) => {
  if (isBugsnagActive) {
    Bugsnag.setUser(user.id, user.email, user.name);
  }
};

export const removeBugsnagUser = () => {
  if (isBugsnagActive) {
    Bugsnag.setUser(null);
  }
};

export const defaultErrorHandler = (error) => {
  // If the error object has a response it means that it's an http error that was handled before
  // For now, all errors are going to be logged in bugsnag to try to understand why are there
  // 404 and 403 errors. 401 errors happen when session timed out and are handled already
  if (isUnprocessableEntity(error) || isUnauthorized(error)) {
    return;
  }

  if (isBugsnagActive) {
    Bugsnag.notify(error, (event) => {
      if (!error.response) {
        return;
      }
      /* eslint-disable-next-line no-param-reassign */
      event.severity = 'info';
      /* eslint-disable-next-line no-param-reassign */
      event.context = `Server responded with ${_.get(error, 'response.request.statusText', 'Error')}`;
      // event.setUser('3', 'bugs.nag@bugsnag.com', 'Bugs Nag')

      // `error.response.config.data` is a string.
      let jsonData;
      try {
        jsonData = JSON.parse(error.response.config.data);
      } catch {
        jsonData = error.response.config.data;
      }

      event.addMetadata('postData', {
        url: error.response.config.url,
        data: jsonData,
        params: error.response.config.params,
        method: error.response.config.method,
        location: window.location.pathname
      });
    });
  } else {
    throw error;
  }
};

export default {
  isBugsnagActive,
  notifyBugsnag,
  defaultErrorHandler,
  setBugsnagUser,
  removeBugsnagUser
};
